import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/HomeView.vue'
import News from '@/views/news/newsView.vue'
import newsDetail from '@/views/news/newsDetail.vue'

import Product from '@/views/product/productView.vue'
import System from '@/views/system/systemView.vue'
import About from '@/views/about/aboutView.vue'
import Join from '@/views/join/joinView.vue'
import joinDetail from '@/views/join/joinDetail.vue'
import joinUs  from '@/views/join/joinUs.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    props:true,
    meta:{
      title:'首页'
    }
  },
  {
    path: '/news/:newsKind', 
    name: 'news',
    component: News,
    props:true,
    meta:{
      title:'新闻中心'
    }

  },
  {
    path: '/product/:productId',
    name: 'product',
    component: Product,
    props:true,
    meta:{
      title:'产品与解决方案'
    }
  },
  {
    path: '/system',
    name: 'system',
    component: System,
    meta:{
      title:'服务体系'
    }
  },

  {
    path: '/about',
    name: 'about',
    component: About,
    meta:{
      title:'关于我们'
    }
  },

  {
    path: '/join/:jobType',
    name: 'join',
    component: Join,
    props:true,
    meta:{
      title:'加入我们'
    }
  },
  {

    path: '/newsdetail/:id',

    name: 'newsdetail',
    component: newsDetail,
    meta:{
      title:'新闻详情'
    }


  },
  {
    path:'/joindetail/:id',
    name:'joindetail',
    component:joinDetail,
    meta:{
      title:'加入我们详情'
    }
  },
  {
    path:'/joinus',
    name:'joinus',
    component:joinUs,
    meta:{
      title:'加盟方式'
    }
  }
  


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
