<template>
  <div>
    <div class="final-box">
      <div class="final" :style="isMobile ? 'text-align: center;' : ''">
        <div class="final-left" :style="isMobile ? 'border:none' : ''">
          <div
            class="final-logo"
            :style="isMobile ? 'text-align: center;' : ''"
          >
            <img
              :src="imgList.imgUrl"
              alt=""
              :style="isMobile ? 'width: 100%;' : ''"
            />
          </div>
          <div class="final-copy">
            Copyright © 2021 The Project by odinnft All Reserved
          </div>
          <div
            class="final-about"
            :style="isMobile ? 'justify-content: center;' : ''"
          >
            <!-- 用户指南 -->
            <!-- <div class="final-line">|</div> -->
            <a href="/about">关于我们</a>
            <div class="final-line">|</div>
            <a href="/join/1">加入我们</a>
            <div class="final-line">|</div>
            <a href="/">首页</a>
            <div class="final-line">|</div>
            <a href="/news/0">新闻咨询</a>
            <div class="final-line">|</div>
            <a href="/product/7">产品内容</a>
          </div>
          <div class="final-contact">
            <a href="/about"> 联系我们 </a>
            <div class="content-box" :style="isMobile ? 'justify-content: center' : ''">
              <div class="time">服务时间 09:00-21:00</div>
              <div>|</div>
              <div class="email">邮箱 guanfang@odinmeta.cn</div>
            </div>
            <div class="number">川公网安备51010702002949号</div>
          </div>
          <div class="friendshipLink" :style="isMobile ? 'text-align: center' : 'padding-left:0'">
            <div class="shiplink-title">友情链接：</div>
            <div style="text-align: start;">
              <el-link type="info" href="http://www.zgxk.org/benhuikanwu/" class="link-title">中国小康研究会</el-link>
              <el-link type="info" href="https://www.mem.gov.cn/" class="link-title">国家应急部</el-link>
              <el-link type="info" href="https://www.mps.gov.cn/" class="link-title">公安部</el-link>
              <el-link type="info" href="http://www.news.cn/" class="link-title">新华网</el-link>
            </div>
          </div>
        </div>

        <div v-if="!isMobile" class="final-right">
          <div
            class="final-right-img"
            v-for="(item, index) in qrList"
            :key="index"
          >
            <img :src="item.imgUrl" alt="" />
            <div style="text-align: center; margin-top: 10px">
              {{ item.imgTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listImg } from "@/api/img";

export default {
  data() {
    return {
      imgList: [],
      qrList: [],
      queryParams: {
        pageNum: 1,
        pageSize: 100,

        imgPosition: "",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.getImgList();
  },
  methods: {
    getImgList() {
      this.loading = true;
      listImg(this.queryParams).then((response) => {
        this.imgList = response.rows;
        //console.log(this.imgList, "imgList");
        this.imgList.forEach((item) => {
          // item.imgUrl=this.$baseImgUrl+item.imgUrl;
          if (item.imgPosition == "logo" && item.imgTitle == "logo2") {
            this.imgList = item;
          }
          if (item.imgPosition == "qrcode") {
            this.qrList.push(item);
          }
        });
        //console.log(this.qrList, "List");
        this.total = response.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
.friendshipLink {
  display: flex;
  align-items: center;
  text-align: start;
  padding: 10px;
}
.shiplink-title{
  width: 100px;
  color: #909399;
}
.link-title {
  padding-left: 10px;
  text-decoration: underline;

}
</style>
<style lang="less" scoped>
a {
  text-decoration: none;
  color: #fff;
}
.final-box {
  background: #20212a;
  .final {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    height: 350px;
    padding: 60px 0 40px;
    .final-left {
      width: 600px;
      height: 208px;
      border-right: 1px solid #fff;
      .final-logo {
        img {
          height: 70px;
        }
      }
      .final-copy {
        height: 16px;
        margin-top: 20px;
        color: #ffffff99;
        font-size: 14px;
      }
      .final-about {
        display: flex;
        margin-top: 20px;
        font-size: 14px;
        .final-line {
          margin: 0 10px;
        }
      }
      .final-contact {
        margin-top: 20px;
        font-size: 16px;
        a {
          text-decoration: none;
          color: #fff;
        }
        .content-box {
          display: flex;
          margin-top: 15px;
          color: #ffffff99;
          font-size: 14px;
          .time {
            margin-right: 10px;
          }
          .email {
            margin-left: 10px;
          }
        }
        .number {
          margin-top: 10px;
          font-size: 14px;
          color: #ffffff99;
        }
      }
    }
    .final-right {
      display: flex;
      justify-content: center;
      width: 600px;
      height: 208px;
      .final-right-img {
        margin: 20px;
        img {
          width: 130px;
          height: 130px;
        }
        p {
          font-size: 15px;
          text-align: center;
        }
      }
    }
  }
}
</style>
