<template>
  <div class="join">
    <div :class="isMobile ? 'mobile-pic' : 'pic'">
      <div :class="isMobile ? 'mobile-group' : 'group'">
        <div class="group-title" v-if="this.queryParams.jobType == 1">
          社会招聘
        </div>
        <div class="group-title" v-else>校园招聘</div>

        <div class="group-content" v-if="this.queryParams.jobType == 1">
          SOCIAL RECRUIMENT
        </div>
        <div class="group-content" v-else>CAMPUS RECRUIMENT</div>
      </div>
    </div>
    <div :class="isMobile ? 'mobile-break' : 'break'">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
        <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <breadCrumb></breadCrumb>
    </div>

    <!-- <div class="nav">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
  <el-menu-item index="1">网思简介</el-menu-item>
  <el-menu-item index="2" >企业文化</el-menu-item>
  <el-menu-item index="3" >荣誉资质</el-menu-item>
  <el-menu-item index="4">企业风采</el-menu-item>
  <el-menu-item index="5">在线留言</el-menu-item>

</el-menu>
    </div> -->
    <div class="social" style="">
      <div>
        <div v-if="!isMobile" class="title-box" style="position: relative">
          <div id="title" class="title" v-if="this.queryParams.jobType == 1">
            社招职位
          </div>
          <div id="title" class="title" v-else>校招职位</div>

          <div class="title-active"></div>
          <div class="title-eng">SOCIAL RECRUIMENT POSITION</div>
        </div>
        <div v-else>
          <div class="com-title" :style="isMobile ? 'padding:0' : ''">
            <h3 class="title-ch">
              {{ queryParams.jobType | jobType }}
              <p class="title-en">SOCIAL RECRUIMENT POSITION</p>
            </h3>
          </div>
        </div>
      </div>
      <div>
        <div class="title-box">
          <div
            id="title"
            :class="isMobile ? 'mobile-title' : 'title'"
            style="margin-top: 100px"
          >
            简历投递：hr@sinontt.com
          </div>
          <div class="title-active"></div>
        </div>
      </div>
      <div class="social-content" :style="isMobile ? 'width:100%' : ''">
        <div
          class="social-content-title"
          :class="{ 'active-button': activeButton === 'gosocial1' }"
          @click="gosocial1"
        >
          <div style="cursor: pointer">技术体系</div>
        </div>
        <div
          class="social-content-title"
          :class="{ 'active-button': activeButton === 'gosocial2' }"
          @click="gosocial2"
        >
          <div style="cursor: pointer">市场体系</div>
        </div>
        <div
          class="social-content-title"
          :class="{ 'active-button': activeButton === 'gosocial3' }"
          @click="gosocial3"
        >
          <div style="cursor: pointer">运营体系</div>
        </div>
      </div>
      <!-- @click="$router.push('/joindetail')" -->
      <div class="social-item" :style="isMobile ? 'margin:0;width:100%' : ''">
        <el-row v-if="!isMobile" :gutter="20">
          <el-col :span="7" v-for="(item, index) in jobList" :key="index"
            ><div class="grid-content bg-purple">
              <div>
                <h3>{{ item.jobName }}</h3>
              </div>
              <!-- <div class="social-item-title2" style=""><p>岗位职责:</p></div> -->
              <div
                class="social-introduction"
                @click="goToJoinDetail(item.id)"
                style="cursor: pointer"
              >
                <p v-html="item.jobDescribe"></p>
              </div></div
          ></el-col>
          <!-- <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div><h3>U3D程序开发</h3></div>
              <div style="font-size: 14px; margin-top: 20px">
                <p>岗位职责:</p>
              </div>
              <div style="font-size: 14px; margin-top: 10px">
                <p>1、负责公司 Unity3D 程序的开发测试；</p>
                <p>2、负责逻辑代码部分的功能实现；</p>
                <p>
                  3、除去目前的 UWP 端程序开发发布, 后续对多端开发进行学习投入。
                </p>
              </div>
            </div></el-col
          >
          <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div><h3>U3D程序开发</h3></div>
              <div style="font-size: 14px; margin-top: 20px">
                <p>岗位职责:</p>
              </div>
              <div style="font-size: 14px; margin-top: 10px">
                <p>1、负责公司 Unity3D 程序的开发测试；</p>
                <p>2、负责逻辑代码部分的功能实现；</p>
                <p>
                  3、除去目前的 UWP 端程序开发发布, 后续对多端开发进行学习投入。
                </p>
              </div>
            </div></el-col
          >
          <el-col :span="7"
            ><div class="grid-content bg-purple">
              <div><h3>U3D程序开发</h3></div>
              <div style="font-size: 14px; margin-top: 20px">
                <p>岗位职责:</p>
              </div>
              <div style="font-size: 14px; margin-top: 10px">
                <p>1、负责公司 Unity3D 程序的开发测试；</p>
                <p>2、负责逻辑代码部分的功能实现；</p>
                <p>
                  3、除去目前的 UWP 端程序开发发布, 后续对多端开发进行学习投入。
                </p>
              </div>
            </div></el-col
          > -->
        </el-row>
        <div v-else class="box">
          <div
            class="mobile-grid-content bg-purple"
            v-for="(item, index) in jobList"
            :key="index"
          >
            <div>
              <h3>{{ item.jobName }}</h3>
            </div>
            <!-- <div class="social-item-title2" style=""><p>岗位职责:</p></div> -->
            <div
              class="social-introduction"
              @click="goToJoinDetail(item.id)"
              style="cursor: pointer"
            >
              <p v-html="item.jobDescribe"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 社招流程 -->
    <div class="process" style="margin-top: 100px">
      <div>
        <div v-if="!isMobile" class="title-box" style="position: relative">
          <div id="title" class="title">社招流程</div>
          <div class="title-active"></div>
          <div class="title-eng">SOCIAL RECRUITMENT PROCESS</div>
        </div>
        <div v-else>
          <div class="com-title" :style="isMobile ? 'padding:0' : ''">
            <h3 class="title-ch">
              社招流程
              <p class="title-en">SOCIAL RECRUITMENT PROCESS</p>
            </h3>
          </div>
        </div>
      </div>
      <div class="process-box" style="">
        <div class="process-box-item" v-for="(item,index) in 4" :key="index" style="">
          <div style="position: relative" class="group-active">
            <img class="group-active-img" src="@/assets/joinUS.png" />
            <i class="el-icon-s-promotion group-i" :style="isMobile ? 'right:0;left:0' : ''"></i>
          </div>
          <div class="process-box-title" style="">网络平台投递</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "@/components/breadCrumb.vue";

import { listJob } from "@/api/job";
export default {
  compoments: {
    breadCrumb,
  },
  data() {
    return {
      jobList: [],
      queryParams: {
        jobSort: 1,
        jobType: 1,
      },
      activeButton: null,
    };
  },
  filters: {
    jobType(val) {
      if (val == 1) {
        return "社会招聘";
      } else {
        return "校园招聘";
      }
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.queryParams.jobType = this.$route.params.jobType;
    //console.log(this.queryParams.jobType, "this.queryParams.jobType");
    this.getList();
  },
  watch: {
    "$route.params.jobType": function (newVal) {
      // 当jobType变化时，更新数据
      this.queryParams.jobType = newVal;
      //this.queryParams.jobSort=1;
      //console.log(this.queryParams.jobSort, "this.queryParams.jobType");
      this.activeButton = null;
      this.queryParams.jobSort = 1;
      this.getList(); // 根据新的newsKind加载数据
    },
  },
  methods: {
    setActiveButton(buttonName) {
      this.activeButton = buttonName;
    },
    goToJoinDetail(id) {
      //console.log("goToJoinDetail", id);
      // 导航到详情页
      this.$router.push(`/joindetail/${id}`);
    },
    /** 查询岗位列表 */
    getList() {
      this.loading = true;
      listJob(this.queryParams)
        .then((response) => {
          this.jobList = response.rows;
          //console.log(this.jobList, "this.jobList");
          this.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching job list:", error);
          this.loading = false;
        });
    },
    gosocial1() {
      this.setActiveButton("gosocial1");
      this.queryParams.jobSort = 1;
      // console.log(this.queryParams.jobSort, "social1");
      this.getList(); // 更新查询参数后重新获取列表
    },
    gosocial2() {
      this.setActiveButton("gosocial2");
      this.queryParams.jobSort = 2;
      // console.log(this.queryParams.jobSort, "social2");
      this.getList(); // 更新查询参数后重新获取列表
    },
    gosocial3() {
      this.setActiveButton("gosocial3");
      this.queryParams.jobSort = 3;
      // console.log(this.queryParams.jobSort, "social3");
      this.getList(); // 更新查询参数后重新获取列表
    },
  },
};
</script>
<style lang="less" scoped>
.com-title {
  padding: 3.55rem 0 3.68rem;
  text-align: center;
  .title-ch {
    position: relative;
    font-size: 1.64rem;
    color: #000;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.64rem;
      transform: translateX(-50%);
      width: 2.73rem;
      height: 0.09rem;
      background-color: #0096a3;
    }
  }
  .title-en {
    position: absolute;
    left: 50%;
    top: 0.91rem;
    transform: translateX(-50%);
    color: rgba(153, 153, 153, 0.15);
    z-index: 1;
    font-size: 1.2rem;
    white-space: nowrap;
    text-indent: 0;
  }
}
.mobile-pic {
  width: 100%;
  height: 300px !important;
  background-image: url(@/assets/join.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .mobile-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .group-title {
      font-size: 50px;
    }
  }
}
.mobile-break {
  padding: 20px 0;
}
.mobile-title {
  position: relative;
  font-size: 26px;
  color: #333;
  font-weight: 600;
  text-align: center;
  .title-active {
    border-bottom: 1px solid skyblue;
    width: 90px;
    height: 10px;
    text-align: center;
    margin: 0 auto;
  }
}
.mobile-grid-content {
  border-radius: 4px;
  height: 200px;
  min-height: 36px;
  padding: 1.14rem 1.82rem;
  color: #000;
  margin: 20px;
}
</style>
<style scoped lang="less">
.join {
  width: 100%;
  background-color: #fff;
}
.pic {
  width: 100%;
  height: 600px;
  background-image: url(@/assets/join.png);
  .group {
    margin: 0 auto;
    padding-top: 200px;
    text-align: center;
    width: 100%;
    .group-title {
      font-size: 50px;
    }
    .group-content {
      margin-top: 20px;
    }
  }
}
.break {
  width: 60%;
  text-align: left;
  margin: 30px auto;
}
.title {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #333;
  font-weight: 600;
}
.title::before {
  content: "";
  //background-image: url("@/assets/before.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-right: 20px;
}
.title::after {
  content: "";
  //background-image: url("@/assets/after.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-left: 20px;
}
.title-eng {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(153, 153, 153, 0.15);
  font-size: 36px;
  top: 0;
  left: 670px;
}
.nav {
  width: 40%;
  margin: 50px auto;
  border-bottom: 1px solid #ccc;
}
.title-active {
  border-bottom: 1px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}

.el-row {
  margin-bottom: 20px;
  color: black;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #f9f9f9;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  height: 200px;
  min-height: 36px;
  padding: 1.14rem 1.82rem;
  margin-bottom: 20px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
p {
  line-height: 1.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
}
.el-icon-s-promotion {
  font-size: 30px;
}

.social {
  width: 100%;
  margin: 0 auto;
  .social-content {
    width: 20%;
    color: #333;
    margin: 0 auto;
    text-align: center;
    display: flex;
    padding-top: 50px;
  }
  .social-content-title {
    flex: 1;
    // border-right: 1px solid #333;

    //text-decoration: none;
    text-align: center;
    margin: 0 auto;
    //margin-left: 30px;
  }
  .social-item {
    margin: 50px 300px;
    text-align: left;
    width: 80%;
    .social-item-title2 {
      font-size: 14px;
      margin-top: 20px;
    }
    .social-introduction {
      font-size: 14px;
      margin-top: 10px;
      height: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.process {
  width: 100%;
  margin: 0 auto;
  .process-box {
    display: flex;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
    .process-box-item {
      flex: 1;
      height: 230px;
      .group-active {
        position: relative;
        .group-active-img {
          width: 73px;
          height: 80px;
        }
        .group-i {
          position: absolute;
          top: 22px;
          right: 176px;
          color: #02acb9;
        }
      }
      .group-active:hover {
        color: #02acb9;
      }
      .process-box-title {
        margin-top: 50px;
        color: #333;
      }
    }
  }
}
.active-button {
  color: #0096a3; /* 你可以自定义高亮颜色 */
  //color: #fff; /* 你可以自定义高亮文本颜色 */
  /* 其他你想要的高亮样式 */
}
</style>
