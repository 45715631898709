<template>
  <div class="join">
    <div class="pic" :class="isMobile ? 'mobile-pic' : ''">
      <div :class="isMobile ? 'mobile-group' : 'group'">
        <div class="group-title">加盟方式</div>

        <!-- <div class="group-content" >
          SOCIAL RECRUIMENT
        </div> -->
      </div>
    </div>
    <div :class="isMobile ? 'mobile-break' : 'break'">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>活动管理</el-breadcrumb-item>
          <el-breadcrumb-item>活动列表</el-breadcrumb-item>
          <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb> -->
      <breadCrumb></breadCrumb>
    </div>
    <div class="social" style="">
      <div v-if="!isMobile">
        <div class="title-box" style="position: relative">
          <div id="title" class="title">加盟方式</div>

          <div class="title-active"></div>
          <div class="title-eng">SOCIAL RECRUIMENT POSITION</div>
        </div>
      </div>
      <div v-else style="padding-bottom: 40px">
        <div class="com-title" :style="isMobile ? 'padding:0' : ''">
          <h3 class="title-ch">
            加盟方式
            <p class="title-en">SOCIAL RECRUIMENT POSITION</p>
          </h3>
        </div>
      </div>
      <div>
        <div class="title-box" :style="isMobile ? '' : 'margin-top: 100px'">
          <div
            id="title"
            class=""
            :class="isMobile ? 'mobile-title' : 'title'"
            v-for="(item, index) in joinOne"
            :key="index"
          >
            加盟热线：{{ item.joinTel }}
          </div>
          <div class="title-active"></div>
          <!-- <div class="title-eng">SOCIAL RECRUIMENT POSITION</div> -->
        </div>
      </div>
      <div class="social-content" :style="isMobile ? 'width:100%' : ''">
        <div
          v-for="(item, index) in joinList"
          :key="index"
          class="social-content-title"
          :class="{ 'active-button': activeButton === 'gosocial' + item.id }"
          @click="gosocial(item.id)"
        >
          <div>{{ item.joinTitle }}</div>
        </div>
        <!-- <button
          class="social-content-title"
          :class="{ 'active-button': activeButton === 'gosocial2' }"
          @click="gosocial2"
        >
          <div style="">安全用电加盟</div>
        </button> -->
      </div>
      <!-- @click="$router.push('/joindetail')" -->
      <!-- <div class="social-item" style="">
        <el-row :gutter="20">
          <el-col :span="7" v-for="(item, index) in jobList" :key="index"
            ><div class="grid-content bg-purple">
              <div>
                <h3>{{ item.jobName }}</h3>
              </div>
             
              <div class="social-introduction" @click="goToJoinDetail(item.id)">
                <p v-html="item.jobDescribe"></p>
              </div></div
          ></el-col>
         
        </el-row>
      </div> -->
      <div>
        <div class="msg-box" :style="isMobile ? 'width:80%' : ''">
          <div
            :class="isMobile ? '' : 'msg'"
            style=""
            v-for="(item, index) in joinOne"
            :key="index"
          >
            <div style="padding-top: 20px; color: black">
              <h2>{{ item.joinTitle }}</h2>
            </div>
            <div style="padding-top: 20px; color: black">
              <p v-html="item.joinDescribe"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 社招流程 -->
    <div class="process" style="margin-top: 100px">
      <div>
        <div v-if="!isMobile" class="title-box" style="position: relative">
          <div id="title" class="title">加盟流程</div>
          <div class="title-active"></div>
          <div class="title-eng">SOCIAL RECRUITMENT PROCESS</div>
        </div>
        <div v-else style="padding-bottom: 40px">
        <div class="com-title" :style="isMobile ? 'padding:0' : ''">
          <h3 class="title-ch">
            加盟流程
            <p class="title-en">SOCIAL RECRUITMENT PROCESS</p>
          </h3>
        </div>
      </div>
      </div>
      <div class="process-box" style="">
        <div class="process-box-item" v-for="(item,index) in 4" :key="index" style="">
          <div style="position: relative" class="group-active">
            <img class="group-active-img" src="@/assets/joinUS.png" />
            <i class="el-icon-s-promotion group-i" :style="isMobile ? 'right:0;left:0' : ''"></i>
          </div>
          <div class="process-box-title" style="">网络平台投递</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "@/components/breadCrumb.vue";

import { listJoin } from "@/api/join";

export default {
  compoments: {
    breadCrumb,
  },
  data() {
    return {
      joinList: [],
      joinOne: [],
      activeButton: null,
      queryParams: {
        id: null,
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.getList();
    setTimeout(() => {
      // 确保 joinList 已经有数据
      if (this.joinList.length > 0) {
        this.queryParams.id = this.joinList[0].id;
        //console.log(this.queryParams.id, "this.queryParams.id");

        // 如果需要，可以根据新的 id 重新获取列表或更新 joinOne
        this.getList();
      } else {
        console.log("joinList is empty, cannot set queryParams.id");
      }
    }, 100); // 1000 毫秒后执行
  },

  methods: {
    setActiveButton(buttonName) {
      this.activeButton = buttonName;
    },

    /** 查询加盟列表 */
    getList() {
      this.loading = true;
      listJoin(this.queryParams).then((response) => {
        this.joinList = response.rows;
        //console.log(this.joinList, "this.joinList");

        if (this.queryParams.id) {
          this.joinList.forEach((item) => {
            if (item.id == this.queryParams.id) {
              //console.log(item.id, "item.id");
              this.joinOne = [item];
              //console.log(this.joinOne,"11111111111111111111111");
            }
          });
        }
        this.total = response.total;
        this.loading = false;
      });
    },
    gosocial(id) {
      //console.log(id, "id");
      this.queryParams.id = id;
      this.setActiveButton("gosocial" + id);
      const targetRoute = {
        path: "/joinus",
        query: {
          id: id,
        },
      };

      const currentRoute = this.$route;
      const isSameRoute =
        currentRoute.fullPath ===
        this.$router.resolve(targetRoute).route.fullPath;

      if (!isSameRoute) {
        this.$router.push(targetRoute);
      }

      this.getList(); // 更新查询参数后重新获取列表
    },
  },
};
</script>
<style lang="less" scoped>
.com-title {
  padding: 3.55rem 0 3.68rem;
  text-align: center;
  .title-ch {
    position: relative;
    font-size: 1.64rem;
    color: #000;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.64rem;
      transform: translateX(-50%);
      width: 2.73rem;
      height: 0.09rem;
      background-color: #0096a3;
    }
  }
  .title-en {
    position: absolute;
    left: 50%;
    top: 0.91rem;
    transform: translateX(-50%);
    color: rgba(153, 153, 153, 0.15);
    z-index: 1;
    font-size: 1.2rem;
    white-space: nowrap;
    text-indent: 0;
  }
}
.mobile-pic {
  height: 400px !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .mobile-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .group-title {
      font-size: 50px;
    }
  }
}
.mobile-break {
  padding: 20px 0;
}
.mobile-title {
  font-size: 18px;
  color: #333;
  text-align: center;
  font-weight: 600;
}
.msg-box {
  width: 60%;
  background-color: #f9f9f9;
  height: 300px;
  margin: 50px auto;
  padding: 0 10px;
  .msg {
    margin-left: 150px;
    width: 70%;
    height: 100%;
  }
}
</style>
<style scoped lang="less">
.join {
  width: 100%;
  background-color: #fff;
}
.pic {
  width: 100%;
  height: 600px;
  background-image: url(@/assets/join.png);
  .group {
    margin: 0 auto;
    padding-top: 200px;
    text-align: center;
    width: 100%;
    .group-title {
      font-size: 50px;
    }
    .group-content {
      margin-top: 20px;
    }
  }
}
.break {
  width: 60%;
  text-align: left;
  margin: 30px auto;
}
.title {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #333;
  font-weight: 600;
}
.title::before {
  content: "";
  //background-image: url("@/assets/before.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-right: 20px;
}
.title::after {
  content: "";
  //background-image: url("@/assets/after.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-left: 20px;
}
.title-eng {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(153, 153, 153, 0.15);
  font-size: 36px;
  top: 0;
  left: 670px;
}
.nav {
  width: 40%;
  margin: 50px auto;
  border-bottom: 1px solid #ccc;
}
.title-active {
  border-bottom: 1px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}

.el-row {
  margin-bottom: 20px;
  color: black;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #f9f9f9;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  height: 200px;
  min-height: 36px;
  padding: 1.14rem 1.82rem;
  margin-bottom: 20px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
p {
  line-height: 2; /* 调整行间距 */
  font-size: 14px; /* 调整字体大小 */
  color: #333; /* 调整字体颜色 */
  text-indent: 2em;
  margin-bottom: 10px; /* 可选：段落间距 */
}
.el-icon-s-promotion {
  font-size: 30px;
}

.social {
  width: 100%;
  margin: 0 auto;
  .social-content {
    width: 20%;
    color: #333;
    margin: 0 auto;
    text-align: center;
    display: flex;
    padding-top: 50px;
  }
  .social-content-title {
    flex: 1;

    text-align: center;
    margin: 0 auto;
  }
  .social-item {
    margin: 50px 300px;
    text-align: left;
    width: 80%;
    .social-item-title2 {
      font-size: 14px;
      margin-top: 20px;
    }
    .social-introduction {
      font-size: 14px;
      margin-top: 10px;
      height: 170px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.process {
  width: 100%;
  margin: 0 auto;
  .process-box {
    display: flex;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
    .process-box-item {
      flex: 1;
      height: 230px;
      .group-active {
        position: relative;
        .group-active-img {
          width: 73px;
          height: 80px;
        }
        .group-i {
          position: absolute;
          top: 22px;
          right: 176px;
          color: #02acb9;
        }
      }
      .group-active:hover {
        color: #02acb9;
      }
      .process-box-title {
        margin-top: 50px;
        color: #333;
      }
    }
  }
}
.active-button {
  color: #0096a3; /* 你可以自定义高亮颜色 */
  //color: #fff; /* 你可以自定义高亮文本颜色 */
  /* 其他你想要的高亮样式 */
}
</style>
