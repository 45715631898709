<template>
  <div class="product">
    <div class="pic" :class="isMobile ? 'mobile-pic' : ''">
      <div :class="isMobile ? 'mobile-group' : 'group'">
        <div class="group-title">{{ productList.productTitle }}</div>
        <!-- <div class="group-content">企业级AI中台的成熟产品，用户的发展就是我们前进的目标</div> -->
      </div>
    </div>
    <div class="break" :style="isMobile ? 'width:100%' : ''">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>活动管理</el-breadcrumb-item>
  <el-breadcrumb-item>活动列表</el-breadcrumb-item>
  <el-breadcrumb-item>活动详情</el-breadcrumb-item>
</el-breadcrumb> -->
      <breadCrumb></breadCrumb>
    </div>

    <!-- <div class="nav">
      <div class="nav-box" style="">
        <a href="#app-content" class="nav-box-item" id="nav-app-cases">应用概述</a>
        <a href="#product-cases" class="nav-box-item" id="nav-app-cases">产品架构</a>
        <a href="#advantage-cases" class="nav-box-item" id="nav-app-cases">产品优势</a>
        <a href="#customer-cases" class="nav-box-item" id="nav-app-cases">客户价值</a>
        <a href="#app-cases" class="nav-box-item" id="nav-app-cases">应用案例</a>
      </div>
    </div> -->

    <div>
      <div class="application" :class="isMobile ? 'mobile-application' : ''">
        <div v-if="!isMobile" class="title-box" style="position: relative">
          <div id="app-content" class="title">应用概述</div>
          <div class="title-active"></div>
          <div class="title-eng">APPLICATION OVERVIEW</div>
        </div>
        <div v-else>
          <div class="com-title" :style="isMobile ? 'padding:0' : ''">
            <h3 class="title-ch">
              应用概述
              <p class="title-en">APPLICATION OVERVIEW</p>
            </h3>
          </div>
        </div>
        <div class="application-content" style="margin-top: 50px">
          <img :class="isMobile ? 'mobile-application-img' : 'application-img'" :src="productList.productImg" alt="" />
          <div :class="isMobile ? 'mobile-application-introduction' : 'application-introduction'">
            <h2>{{ productList.productTitle }}</h2>
            <div class="introduction-active"></div>
            <p
              v-html="productList.productDescribe"
              class="introduction-content"
            ></p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="architecture" style="">
      <div>
        <div class="title-box">
      <div id="product-cases" class="title" style="color:#fff">产品架构</div>
      <div class="title-active"></div>
      <div class="title-eng">SYSTEM ARCHITECTURE</div>
    </div>
      </div>
      <div >
        <img class="archimg" src="@/assets/arch1.png" alt="" style="">
      </div>
    </div>

    <div>
      <div class="advantages" style="">
        <div class="title-box">
      <div id="advantage-cases" class="title">产品优势</div>
      <div style="border-bottom: 1px solid skyblue; width: 90px; height: 10px; text-align: center;margin: 0 auto;"></div>

   
      <div class="title-eng">PRODUCT ADVANTAGES</div>
          </div>
          
      </div>
      <div class="advantages-content" style="">
        <div class="advantages-item" style="">
          <img class="advantages-img" src="@/assets/product2.png" alt="" style=" ">
          <h2 class="advantages-title" style="">专业</h2>
          <div class="advantages-title2" style="">参照行业模型和特征指标</div>
          <div class="title-active"></div>
          <p class="advantages-introduction" style="">AI能力中心的各种AI核心和专业能力形成互为推进的良性循环，助力企业迅速形成AI中台的合理框架</p>
        </div>
        <div style="flex: 1;">
          <img src="@/assets/product3.png" alt="" style="width: 100px; height: 100px; margin: 0 auto; ">
          <h2 style="margin-top: 20px;">专业</h2>
          <div style="margin-top: 10px;">参照行业模型和特征指标</div>
          <div style="border-bottom: 1px solid skyblue; width: 90px; height: 10px; text-align: center;margin: 0 auto;"></div>
          <p style="width: 90%; text-align: center; margin: 10px auto;">AI能力中心的各种AI核心和专业能力形成互为推进的良性循环，助力企业迅速形成AI中台的合理框架</p>
        </div>
        <div style="flex: 1;">
          <img src="@/assets/product4.png" alt="" style="width: 100px; height: 100px; margin: 0 auto; ">
          <h2 style="margin-top: 20px;">专业</h2>
          <div style="margin-top: 10px;">参照行业模型和特征指标</div>
          <div style="border-bottom: 1px solid skyblue; width: 90px; height: 10px; text-align: center;margin: 0 auto;"></div>
          <p style="width: 90%; text-align: center; margin: 10px auto; margin-bottom: 50px;">AI能力中心的各种AI核心和专业能力形成互为推进的良性循环，助力企业迅速形成AI中台的合理框架</p>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="title-box">
      <div id="customer-cases" class="title">客户价值</div>
      <div class="title-active"></div>
      <div class="title-eng">CUSTOMER VALUE</div>
          </div>
      </div>
      <div></div>
    </div>
    <div>
      <div>
        <div class="title-box">
      <div id="app-cases" class="title">应用案例</div>
      <div class="title-active"></div>
      <div class="title-eng">APPLICATION CASES</div>
          </div>
      </div>
      <div class="cases-box" style="">
        <img class="cases-img" src="@/assets/product5.jpg" alt="" style="">
        <div class="cases-content" style=" ">
          <h2 class="cases-title1" style="">运营商AI建设</h2>
          <h4 class="cases-title2" style="">建设背景:</h4>
          <p class="cases-introduction" style="">运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设</p>
          <h4 class="cases-title3" style="">建设效果:</h4>
          <p class="cases-introduction2" style="">运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设运营商AI建设</p>

        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import breadCrumb from "@/components/breadCrumb.vue";
import { getProduct } from "@/api/product.js";

export default {
  compoments: {
    breadCrumb,
  },
  data() {
    return {
      productList: [],
      queryParams: {
        productId: "",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.queryParams.productId = this.$route.params.productId;
    //console.log(this.queryParams.id,"111.id");
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      getProduct(this.queryParams.productId).then((response) => {
        this.productList = response.data;
        this.productList.productImg =
          this.$baseImgUrl + this.productList.productImg;
        //console.log(this.productList,"productList");
        // this.total = response.total;
        // this.loading = false;
      });
    },
  },
  watch: {
    "$route.params.productId": function (newVal) {
      // 当newsKind变化时，更新数据
      this.queryParams.productId = newVal;
      this.getList(); // 根据新的newsKind加载数据
    },
  },
};
</script>

<style lang="less" scoped>
.mobile-pic {
  height: 400px !important;
  .mobile-group {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .group-title {
      font-size: 50px;
    }
  }
}
.mobile-application{
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  .mobile-application-img{
    width: 200px;
    height: 200px;
  }
  .mobile-application-introduction{
    color: #333;
    .introduction-active {
        border-bottom: 1px solid skyblue;
        width: 90px;
        height: 30px;
      }
      .introduction-content {
        padding-top: 30px;
        font-size: 14px;
      }
  }
}
</style>

<style scoped lang="less">
.product {
  width: 100%;
  background-color: #fff;
}
.pic {
  width: 100%;
  height: 600px;
  background: url(@/assets/product.png) no-repeat center;
  background-size: cover;
  .group {
    margin: 0 auto;
    padding-top: 200px;
    text-align: center;
    width: 100%;
    .group-title {
      font-size: 50px;
    }
    .group-content {
      margin-top: 20px;
    }
  }
}
.break {
  width: 60%;
  text-align: left;
  margin: 30px auto;
}
.title {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #333;
}
.title::before {
  content: "";
  //background-image: url("@/assets/before.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-right: 20px;
}
.title::after {
  content: "";
  //background-image: url("@/assets/after.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-left: 20px;
}
.title-eng {
  position: absolute;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(153, 153, 153, 0.15);
  font-size: 36px;
  top: 0;
  left: 350px;
}
.nav {
  width: 100%;
  height: 75px;
  line-height: 75px;
  margin: 0 auto;
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding-top: 50px;
  color: #333;
  .nav-box {
    width: 45%;
    display: flex;
    margin: 0 auto;
    text-align: center;
    .nav-box-item {
      flex: 1;
    }
  }
}
.title-active {
  border-bottom: 1px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}
.application {
  width: 60%;
  margin: 0 auto;
  margin-top: 100px;
  padding-bottom: 100px;
  .com-title {
    padding: 3.55rem 0 3.68rem;
    text-align: center;
    .title-ch {
      position: relative;
      font-size: 1.64rem;
      color: #000;
      z-index: 2;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -0.64rem;
        transform: translateX(-50%);
        width: 2.73rem;
        height: 0.09rem;
        background-color: #0096a3;
      }
    }
    .title-en {
      position: absolute;
      left: 50%;
      top: 0.91rem;
      transform: translateX(-50%);
      color: rgba(153, 153, 153, 0.15);
      z-index: 1;
      font-size: 1.2rem;
      white-space: nowrap;
      text-indent: 0;
    }
  }
  .application-content {
    display: flex;
    .application-img {
      width: 660px;
      height: 340px;
      flex: 1;

      transition: transform 0.3s ease-in-out;
    }
    .application-img:hover {
      transform: scale(1.02);
      transform-origin: center center;
    }
    .application-introduction {
      flex: 1;
      padding-top: 80px;
      margin-left: 60px;
      color: #333;
      .introduction-active {
        border-bottom: 1px solid skyblue;
        width: 90px;
        height: 30px;
      }
      .introduction-content {
        padding-top: 30px;
        font-size: 14px;
      }
    }
  }
}

.cases-img:hover {
  transform: scale(1.02);
}
.architecture {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: rgb(31, 37, 49);
  padding-top: 50px;
  .archimg {
    width: 80%;
    margin: 50px auto;
  }
}
.advantages {
  width: 60%;
  margin: 50px auto;
}
.advantages-content {
  width: 100%;
  display: flex;
  margin: 20px auto;
  text-align: center;
  .advantages-item {
    flex: 1;
    .advantages-img {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
    .advantages-title {
      margin-top: 20px;
      color: #333;
    }
    .advantages-title2 {
      margin-top: 10px;
      color: #333;
    }
    .advantages-introduction {
      width: 90%;
      text-align: center;
      margin: 10px auto;
      color: #333;
    }
  }
}
.cases-box {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  display: flex;
  padding-bottom: 100px;
  .cases-img {
    width: 880px;
    height: 560px;
    flex: 1;
    transition: transform 0.3s ease-in-out;
  }
  .cases-content {
    flex: 1;
    margin-left: 40px;
    margin-top: 80px;
    .cases-title1 {
      color: #333;
    }
    .cases-title2 {
      margin-top: 20px;
      color: #333;
    }
    .cases-introduction {
      margin-top: 20px;
      font-size: 14px;
      color: #333;
    }
    .cases-title3 {
      margin-top: 20px;
      color: #333;
    }
    .cases-introduction2 {
      margin-top: 20px;
      font-size: 14px;
      color: #333;
    }
  }
}
p {
  color: #333;
  line-height: 2.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
}
a {
  text-decoration: none;
  color: #333;
}
</style>
