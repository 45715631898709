<template>
  <div class="homePage">
    <zm-swiper :srcList="srcList"></zm-swiper>
    <div :class="isMobile ? 'mobile-cabalities' : 'cabalities-box'" style="">
      <div class="capabilities">
        <div>
          <div :class="isMobile ? 'mobile-box' : 'title-box'">
            <div id="title" class="title" style="">核心产品能力</div>
            <div class="title-active"></div>

            <div class="title-eng" style="">CORE PRODUCT CAPABILITIES</div>
          </div>
        </div>
        <div
          :class="isMobile ? 'mobile-content' : 'capabilities-content'"
          style="margin-top: 50px"
        >
          <div
            :class="isMobile ? 'mobile-item' : 'capabilities-item'"
            v-for="(item, index) in capabilitiesList"
            :key="index"
          >
            <img :src="item.imgUrl" alt="" class="pic" />

            <h3 class="capalibilities-title">{{ item.imgTitle }}</h3>
            <p class="capalibilities-introduction">
              {{ item.imgDescribe }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" class="solution-box" style="height: 1003px">
      <div class="solution">
        <div class="title-box2" style="position: relative">
          <div
            id="title2"
            class="title2"
            style="color: #fff; position: relative; z-index: 1"
          >
            解决方案
          </div>
          <div class="title-active"></div>

          <div
            class="title-eng2"
            style="
              position: absolute;
              top: 100px;
              right: 570px;
              font-size: 36px;
            "
          >
            THE SOLUTION
          </div>
        </div>
        <div class="solution-content" style="margin-top: 50px">
          <el-row :gutter="0">
            <el-col :span="6" v-for="(item, index) in productList" :key="index">
              <div
                class="group img1"
                @click="goProduct(item.id)"
                :style="{ backgroundImage: 'url(' + item.productImg + ')' }"
              >
                <!-- 遮罩层默认隐藏 -->
                <div class="overlay" @click.stop="goProduct(item.id)">
                  <div
                    style="
                      margin: 30px;
                      border-bottom: 1px solid #fff;
                      height: 50px;
                    "
                  >
                    <h3 style="font-weight: bold">{{ item.productTitle }}</h3>
                  </div>
                  <p style="text-align: left">{{ item.productDescribe }}</p>
                </div>
                <h3 class="group-title" style="padding-top: 100px">
                  {{ item.productTitle }}
                </h3>
                <!-- <div class="group-content">{{ item.productDescribe }}</div> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div v-else class="mobile-solve">
      <div class="com-title">
        <h3 class="title-ch">
          解决方案
          <p class="title-en">THE SOLUTION</p>
        </h3>
      </div>
      <div class="solver-container">
        <div class="main-center">
          <div class="tabBox1">
            <div class="tab-title">
              <h3>解决方案</h3>
              <div class="title-en">SOLUTION</div>
            </div>
          </div>
          <div
            class="solve-item"
            :style="{ backgroundImage: 'url(' + item.productImg + ')' }"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div class="small-box">
              <h3>{{ item.productTitle }}</h3>
              <div class="item-introduction">
                {{ item.productDescribe }}
              </div>
              <div class="item-btn" v-if="index < 4">
                <div class="more" @click="goProduct(item.id)">了解更多</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="core-product">
      <div class="core-product-content" style="">
        <div v-if="!isMobile" class="title-box2" style="position: relative">
          <div
            id="title2"
            class="title2"
            style="position: relative; z-index: 1"
          >
            核心产品
          </div>
          <div class="title-active"></div>

          <div
            class="title-eng2"
            style="
              position: absolute;
              z-index: 0;
              top: 0;
              right: 800px;
              font-size: 36px;
            "
          >
            CORE PRODUCTS
          </div>
        </div>
        <div v-else class="com-title">
          <h3 class="title-ch">
            核心产品
            <p class="title-en">CORE PRODUCTS</p>
          </h3>
        </div>
        <div
          class="cor-tabs"
          :class="isMobile ? 'mobile-cor-tabs' : ''"
          style=""
        >
          <div
            v-for="(item, index) in copiedList"
            :key="index"
            @click="goDetail(item.id)"
            class="cor-tab-item"
            :class="{ 'active-button': activeButton === 'godetail' + item.id }"
          >
            {{ item.productTitle }}
          </div>
        </div>
        <div
          class="cor-content"
          :class="isMobile ? 'mobile-cor-content' : ''"
          style=""
        >
          <div
            :class="isMobile ? 'mobile-cor-item' : 'cor-content-item'"
            style=""
          >
            <h2 class="cor-content-title" style="">
              {{ coreOne.productTitle }}
            </h2>
            <p
              v-html="coreOne.productDescribe"
              class="cor-content-introduction"
              style=""
            ></p>
          </div>
          <div :class="isMobile ? 'mobile-aiimg' : 'aiimg'">
            <img class="aiimg1" :src="coreOne.productImg" alt="" style="" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" class="contact">
      <div class="contact-content" style="">
        <h3 class="contact-title">创新服务 智慧中国</h3>
        <p class="contact-introduction">
          —— 致力于为客户提供优质的服务与全面的数智化整体解决方案 ——
        </p>
        <button class="contact-button" @click="$router.push('/about')">
          联系我们>
        </button>
      </div>
    </div>
    <div v-else class="mobile-contact">
      <div class="mobile-contact-txt">
        <h3>创新服务 智慧中国</h3>
        <div class="mobile-introduction">
          — — 致力于为客户提供优质的服务与全面的数智化整体解决方案 — —
        </div>
        <div class="mobile-submitBtn" @click="$router.push('/about')">联系我们</div>
      </div>
    </div>
  </div>
</template>

<script>
import zmSwiper from "@/components/zmSwiper.vue";

import { listImg } from "@/api/img";
import { coreList, getProduct, listProduct } from "@/api/product";
export default {
  components: {
    zmSwiper,
  },
  data() {
    return {
      srcList: [
        // {
        //   src: "http://www.sinontt.com/template/default/video/banner1.mp4",
        // },
      ],
      activeButton: null,
      imgList: [],
      coreList: [],
      copiedList: [],
      productList: [],
      coreOne: [],
      capabilitiesList: [],
      //solutionList: [],
      queryParams: {
        id: null,
        // imgPosition:"index"
        pageSize: 999,
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    //this.queryParams.id=this.$route.query.id;
    // this.$store.dispatch('detectDeviceType');
    console.log(this.isMobile, "this.$store.state.isMobile");
    this.getList();
    this.getCore();
    this.getProduct();
  },
  watch: {
    "$route.params.id": function (newVal) {
      // 当newsKind变化时，更新数据
      this.queryParams.id = newVal;
      this.getCore(); // 根据新的newsKind加载数据
    },
  },
  methods: {
    setActiveButton(buttonName) {
      this.activeButton = buttonName;
    },
    goDetail(id) {
      //console.log(id,"id");
      this.queryParams.id = id;
      this.setActiveButton("godetail" + id);
      this.$router.push({ path: "/", query: { id: id } }).catch((err) => {
        // 忽略重复导航的错误
        if (err.name !== "NavigationDuplicated") {
          // 处理其他错误
          throw err;
        }
      });
      this.getCore();
    },
    goProduct(id) {
      //console.log(id,"id");
      this.$router
        .push({ name: "product", params: { productId: id } })
        .catch((err) => {
          // 忽略重复导航的错误
          if (err.name !== "NavigationDuplicated") {
            // 处理其他错误
            throw err;
          }
        });
    },
    getProduct() {
      //this.loading = true;
      (this.queryParams.pageSize = 8), (this.queryParams.pageNum = 1);
      listProduct(this.queryParams).then((response) => {
        this.productList = response.rows;
        this.productList.forEach((item) => {
          item.productImg = this.$baseImgUrl + item.productImg;
        });
        //console.log(this.productList, "this.productList");
        //this.loading = false;
        //console.log(this.productList, "this.productList");
      });
    },
    /** 查询照片列表 */
    getList() {
      this.loading = true;
      listImg(this.queryParams).then((response) => {
        this.imgList = response.rows;
        //console.log("imglist",this.imgList);
        for (const img of this.imgList) {
          // 在这里处理每个 img 对象
          //console.log(img);
          img.imgUrl = this.$baseImgUrl + img.imgUrl;
          //console.log(img.imgUrl,"img.imgUrl");
          if (img.imgPosition == "index") {
            this.srcList.push(img.imgUrl);
          }
          if (img.imgPosition == "capabilities") {
            this.capabilitiesList.push(img);
          }
          // if (img.imgPosition == "solution") {
          //   this.solutionList.push(img);
          // }

          // console.log(this.srcList,"this.srcList");
        }
        //console.log(this.capabilitiesList,"1111");
        //console.log(this.solutionList,"this.capabilitiesList");
        this.total = response.total;
        this.loading = false;
      });
    },
    getCore() {
      this.loading = true;

      coreList(this.queryParams).then((response) => {
        this.queryParams.id = this.$route.query.id;
        this.coreList = response.rows;
        this.coreList.forEach((item) => {
          item.productImg = this.$baseImgUrl + item.productImg;
        });
        this.copiedList = this.coreList.slice(0, 3);
        //console.log(this.copiedList,"this.copiedList");
        if (this.queryParams.id) {
          this.copiedList.forEach((item) => {
            if (item.id == this.$route.query.id) {
              this.coreOne = item;
              //console.log(this.coreOne,"this.coreOne");
            }
          });
        } else {
          this.coreOne = this.copiedList[0];
        }
        // this.loading = false;
        // if(this.queryParams.id){
        //   this.copiedList.forEach((item)=>{
        //     if(item.id=this.queryParams.id){
        //       this.coreOne=item;

        //     }
        //   })
        //   console.log(this.coreOne,"this.coreOne");
        // }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-cabalities {
  background-color: #fff;
  color: #000;
  padding: 30px 10px;
  text-align: center;
  .capabilities {
    .mobile-box {
      .title {
        font-size: 26px;
        color: #000000;
        font-weight: 700;
      }
      .title-eng {
        color: rgba(153, 153, 153, 0.15);
      }
    }
    .mobile-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: baseline;
      .mobile-item {
        width: 48%;
        margin-right: 2%;
        margin-bottom: 3rem;
        font-size: 13px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .capalibilities-title {
          margin: 1.82rem 0 0.82rem;
        }
        .capalibilities-introduction {
          text-indent: 0;
          line-height: 1.36rem;
          color: #666666;
          font-size: 10px;
        }
      }
    }
  }
}
.mobile-solve {
  padding-bottom: 3.73rem;
  background: url(@/assets/home3x.jpg) no-repeat center;
  background-size: cover;
  .com-title {
    padding: 3.55rem 0 3.68rem;
    text-align: center;
    .title-ch {
      position: relative;
      font-size: 1.64rem;
      color: #fff;
      z-index: 2;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -0.64rem;
        transform: translateX(-50%);
        width: 2.73rem;
        height: 0.09rem;
        background-color: #0096a3;
      }
    }
    .title-en {
      position: absolute;
      left: 50%;
      top: 0.91rem;
      transform: translateX(-50%);
      color: rgba(255, 255, 255, 0.08);
      z-index: 1;
      font-size: 1.2rem;
      white-space: nowrap;
      text-indent: 0;
    }
  }
  .solver-container {
    padding-top: 3.55rem;
    background-color: rgba(36, 38, 50, 0.9);
    .main-center {
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;
      .tabBox1 {
        position: relative;
        height: 11.82rem;
        padding: 1.36rem;
        box-sizing: border-box;
        background: url(http://www.sinontt.com/template/default/img/index-solve-01@3x.jpg)
          no-repeat center;
        background-size: cover;
        border: 0.05rem solid rgba(255, 255, 255, 0.1);
        width: 100%;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.23rem;
          height: 100%;
          background-color: #02acb9;
        }
        h3 {
          font-size: 1rem;
          color: #ffffff;
        }
        .title-en {
          font-size: 0.73rem;
          color: #02acb9;
        }
      }
      .solve-item {
        background: url(http://www.sinontt.com/template/default/img/index-solve-01-01@3x.jpg)
          no-repeat center;
        background-size: cover;
        width: 100%;
        border: 0.05rem solid rgba(255, 255, 255, 0.1);
        .small-box {
          height: 11.82rem;
          padding: 1.36rem;
          box-sizing: border-box;
          h3 {
            position: relative;
            font-size: 0.91rem;
            color: #fff;
            margin-bottom: 2.36rem;
            &::after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -1.23rem;
              width: 0.91rem;
              height: 0.05rem;
              background-color: #02abb9;
            }
          }
          .item-introduction {
            font-size: 0.55rem;
            line-height: 1.09rem;
            color: #fff;
            margin-bottom: 2.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .item-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .more {
              width: 6.55rem;
              height: 1.64rem;
              line-height: 1.64rem;
              text-align: center;
              border: 1px solid #ffffff;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.mobile-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15.91rem;
  background: url(@/assets/home2.jpg) no-repeat center;
  background-size: cover;
  .mobile-contact-txt {
    text-align: center;
    h3 {
      font-size: 1rem;
      position: relative;
      color: #02abb9;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: -1.05rem;
        transform: translateX(-50%);
        width: 20.09rem;
        height: 0.09rem;
        background: linear-gradient(to left, transparent, #02abb9, transparent);
      }
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -1.05rem;
        transform: translateX(-50%);
        width: 20.09rem;
        height: 0.09rem;
        background: linear-gradient(to left, transparent, #02abb9, transparent);
      }
    }
    .mobile-introduction {
      font-size: 0.73rem;
      line-height: 24px;
      padding: 0 22%;
      margin: 2.32rem 0 1rem;
    }
    .mobile-submitBtn {
      width: 6.82rem;
      height: 1.82rem;
      line-height: 1.82rem;
      text-align: center;
      color: #fff;
      background-color: #02abb9;
      display: inline-block;
    }
  }
}
</style>

<style scoped lang="less">
.el-carousel__arrow {
  background: #171a20 !important;
  border: 1px solid #fff;
  font-size: 20px;
}
.yourClassName {
  color: red !important;
}
.homePage {
  .line {
    width: 100%;
    height: 2px;
    background-color: #373d4b;
    position: relative;
    left: 0;
    top: 93px;
    z-index: 1;
  }
  .block {
    cursor: pointer;
  }
  .cabalities-box {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 100px;
    .capabilities {
      width: 70%;
      margin: 30px auto;
      text-align: center;
      .title-box {
        position: relative;
        margin-top: 100px;
        .title {
          display: flex;
          z-index: 1;
          position: relative;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          color: #171a20;
          font-weight: 600;
        }
        .title::before {
          content: "";
          //background-image: url("@/assets/before.png");
          background-size: cover;
          width: 142px;
          height: 16px;
          margin-right: 20px;
        }
        .title::after {
          content: "";
          //background-image: url("@/assets/after.png");
          background-size: cover;
          width: 142px;
          height: 16px;
          margin-left: 20px;
        }
        .title-eng {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          color: rgba(153, 153, 153, 0.15);
          position: absolute;
          top: 0;
          right: 400px;
          font-size: 36px;
          z-index: 0;
        }
      }
      .capabilities-content {
        display: flex;
        margin-top: 30px;

        color: #171a20;
        .capabilities-item {
          flex: 1;
          margin-left: 20px;
          .pic {
            width: 110px;
            height: 120px;
          }
          .capalibilities-title {
            font-size: 24px;
            margin: 20px auto;
          }
          .capabilities-introduction {
            font-size: 16px;
          }
        }
      }
    }
  }

  .video {
    position: relative;
    margin: auto;
    width: 1200px;
    height: 650px;
    margin-top: 47px;
    .playKey {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .product {
    margin-top: 47px;
    .product-block {
      width: 1200px;
      height: 389px;
      margin: auto;
      .product-card-box {
        display: flex;
        cursor: pointer;
      }
    }
  }
  .memorabilia {
    background: url(https://img.odinnft.cn/pc/v4.0/dsjBg.png) no-repeat 50%;
    background-size: cover;
    margin: 50px auto 0;
    padding-bottom: 80px;
    height: 600px;
    display: flex;
    justify-content: center;
    .memorabilia-block {
      width: 98%;
      max-width: 1200px;
      margin: auto;
      .card-box {
        display: flex;
        justify-content: center;
        .memorabilia-card {
          width: 283px;
          height: 330px;
          margin: 10px;
          .memorabilia-card-time {
            text-align: center;
            color: hsla(0, 0%, 100%, 0.9);
            font-size: 24px;
          }
          .card-circle {
            width: 22px;
            height: 22px;
            border: 1px solid #fff;
            border-radius: 50%;
            position: relative;
            margin: 38px auto 0;
            z-index: 9;
            background-color: #171a20;
          }
          .card-circle::after {
            width: 14px;
            height: 14px;
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -7px 0 0 -7px;
            background-color: #fff;
            border-radius: 50%;
            overflow: hidden;
          }
          .memorabilia-card-title {
            background-color: #262a34;
            width: 210px;
            padding: 20px;
            margin: 20px auto 0;
            border-radius: 10px;
            color: #fff;
            min-height: 180px;
          }
        }
      }
    }
  }
  .solution-box {
    width: 100%;
    height: 900px;
    margin: 0 auto;
    background-image: url("@/assets/home3x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .solution {
      width: 74%;
      margin: 0 auto;
      text-align: center;
      .title-box2 {
        position: relative;
        margin: 0 auto;
        padding-top: 100px;

        .title2 {
          position: relative;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
        }
        .title2::before {
          content: "";
          //background-image: url("@/assets/before.png");
          background-size: cover;
          width: 142px;
          height: 16px;
          margin-right: 20px;
        }
        .title2::after {
          content: "";
          //background-image: url("@/assets/after.png");
          background-size: cover;
          width: 142px;
          height: 16px;
          margin-left: 20px;
        }
        .title-eng2 {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          color: rgba(153, 153, 153, 0.15);
        }
      }

      .solution-content {
        padding-bottom: 100px;
        .group {
          width: 350px;
          height: 260px;
          margin-top: 5px;
          cursor: pointer;
          //margin-left: 10px;
          // overflow: hidden;
          //transition: all 0.3s ease;
          .group-content {
            margin-top: 30px;
            margin-top: 10px;
            color: #fff;
          }
          //     .group:hover {
          //   width: 500px; /* 鼠标悬停时的宽度 */
          //   height: 300px; /* 鼠标悬停时的高度 */
          // }
        }
      }
    }
  }
  .title-box2 {
    margin: 100px 0;

    .title2 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      color: black;
      font-weight: 600;
    }
    .title2::before {
      content: "";
      //background-image: url("@/assets/before.png");
      background-size: cover;
      width: 142px;
      height: 16px;
      margin-right: 20px;
    }
    .title2::after {
      content: "";
      //background-image: url("@/assets/after.png");
      background-size: cover;
      width: 142px;
      height: 16px;
      margin-left: 20px;
    }
    .title-eng2 {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      color: rgba(153, 153, 153, 0.15);
    }
  }

  .img1 {
    background-image: url(@/assets/index-solve-013x.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }
  .img2 {
    background-image: url(@/assets/index-solve-01-01.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }
  .img3 {
    background-image: url(@/assets/index-solve-01-02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }
  .img4 {
    background-image: url(@/assets/index-solve-01-03.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }

  .img5 {
    background-image: url(@/assets/index-solve-01-04.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }
  .img6 {
    background-image: url(@/assets/index-solve-01-05.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }
  .img7 {
    background-image: url(@/assets/index-solve-01-06.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }
  .img8 {
    background-image: url(@/assets/index-solve-01-07.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 260px;
    margin-top: 30px;
  }

  .contact {
    width: 100%;
    height: 350px;
    margin: 0 auto;
    text-align: center;
    //background-color: pink;
    background-image: url(@/assets/home2.jpg);

    .contact-content {
      width: 50%;
      margin: 0 auto;
      padding-top: 50px;
      .contact-title {
        border-top: 1px solid #02abb9;
        padding: 20px;
        border-bottom: 1px solid #02abb9;
        color: #02abb9;
        font-size: 30px;
        width: 40%;
        margin: 0 auto;
      }
      .contact-introduction {
        width: 100%;
        font-size: 26px;
        margin: 20px auto;
      }
      .contact-button {
        width: 150px;
        height: 40px;
        background-color: #02abb9;
        color: #fff;
        border: none;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
  // .aiimg{
  //   background-image: url(@/assets/ai.png);
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   //width: 100%;
  //   //height: 100%;
  // }
}
p {
  text-indent: 2em;
  line-height: 2.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
}
.core-product {
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  background-image: url(@/assets/home.jpg);
  .core-product-content {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
    .com-title {
      padding: 3.55rem 0 3.68rem;
      text-align: center;
      .title-ch {
        position: relative;
        font-size: 1.64rem;
        color: #000;
        z-index: 2;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -0.64rem;
          transform: translateX(-50%);
          width: 2.73rem;
          height: 0.09rem;
          background-color: #0096a3;
        }
      }
      .title-en {
        position: absolute;
        left: 50%;
        top: 0.91rem;
        transform: translateX(-50%);
        color: rgba(153, 153, 153, 0.15);
        z-index: 1;
        font-size: 1.2rem;
        white-space: nowrap;
        text-indent: 0;
      }
    }
    .mobile-cor-tabs {
      width: 100% !important;
    }
    .cor-tabs {
      width: 30%;
      color: #000;
      display: flex;
      margin: 0 auto;
      text-align: center;
      .cor-tab-item {
        flex: 1;
        //color: aqua;
        //color: #171a20;
        font-size: 17px;
        cursor: pointer;
      }

      .cor-tab-item2 {
        flex: 1;
        color: aqua;
        font-size: 17px;
      }
    }
    .mobile-cor-content {
      padding: 0 10px;
      height: 500px !important;
    }
    .cor-content {
      width: 80%;
      margin: 50px auto;
      text-align: left;
      position: relative;
      height: 660px;
      .mobile-cor-item {
        .cor-content-title {
          color: #02abb9;
          font-size: 30px;
        }
        .cor-content-introduction {
          padding-top: 50px;
          color: black;
          font-size: 14px;
        }
      }
      .cor-content-item {
        width: 40%;
        padding-top: 100px;
        .cor-content-title {
          color: #02abb9;
          font-size: 30px;
        }
        .cor-content-introduction {
          padding-top: 50px;
          color: black;
          font-size: 14px;
        }
      }
    }
    .mobile-aiimg {
      width: 70%;
      height: 200px;
      position: absolute;
      right: 10px;
      .aiimg1 {
        width: 100%;
      }
    }
    .aiimg {
      width: 70%;
      height: 200px;
      position: absolute;
      right: 10px;
      top: 0;
      .aiimg1 {
        width: 100%;
      }
    }
  }
}
.title-active {
  border-bottom: 2px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}
/deep/ .active-button {
  color: #00b5c2;
  //color: pink;
}
.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 201%;
  background-image: url("@/assets/solutionbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  //background: #00b5c2; /* 半透明黑色背景 */
  opacity: 0; /* 默认状态下遮罩层透明度为0，即不可见 */
  transition: opacity 0.3s ease; /* 添加过渡效果 */
  cursor: pointer; /* 遮罩层上的鼠标样式设置为指针 */
}

.group.img1:hover .overlay {
  opacity: 1; /* 鼠标悬停时，遮罩层透明度为1，即可见 */
}

.group.img1 {
  position: relative; /* 确保遮罩层能够相对于这个容器定位 */
  /* 其他样式... */
}
</style>
