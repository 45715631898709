import { render, staticRenderFns } from "./joinDetail.vue?vue&type=template&id=48e59cef&scoped=true"
import script from "./joinDetail.vue?vue&type=script&lang=js"
export * from "./joinDetail.vue?vue&type=script&lang=js"
import style0 from "./joinDetail.vue?vue&type=style&index=0&id=48e59cef&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e59cef",
  null
  
)

export default component.exports