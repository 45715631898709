<template>
  <div class="tabbar-box">
    <div class="collapse">
      <div class="logo-box">
        <img :src="imgList.imgUrl" alt="" />
      </div>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="menu-title">
          {{ menuObj.title }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in menuList"
            :command="item"
            :key="index"
            >{{ item.title }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-menu
        :router="true"
        :default-active="activeIndex2"
        @select="handleSelect"
        mode="horizontal"
        background-color="#171a20"
        text-color="#fff"
        active-text-color="#409eff"
      >
        <el-submenu index="">
          <template slot="title">
            <span>{{ menuObj.title }}</span>
          </template>
          <el-menu-item
            v-for="(item, index) in menuList"
            :index="item.router"
            :key="index"
            >{{ item.title }}</el-menu-item
          >
        </el-submenu>
      </el-menu> -->
    </div>
  </div>
</template>

<script>
import { listImg } from "@/api/img";
export default {
  name: "tabbar",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "/",
      queryParams: {
        imgPosition: "logo",
      },
      imgList: [],
      // 存储用户选择的导航项
      isCollapse: false,
      menuList: [
        {
          title: "首页",
          router: "/",
        },
        {
          title: "产品内容",
          router: "/product/7",
        },
        {
          title: "新闻资讯",
          router: "/news/0",
        },
        {
          title: "社会招聘",
          router: "/join/1",
        },
        {
          title: "加盟方式",
          router: "/joinus",
        },
        {
          title: "关于我们",
          router: "/about",
        },
      ],
      menuObj: {
        title: "首页",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    console.log(this.isMobile, "isMobile");
    this.getImgList();
    // 使用 Vue Router 的全局前置守卫来监听路由变化
    this.$router.beforeEach((to, from, next) => {
      // 在路由变化之前更新 activeIndex2
      this.activeIndex2 = to.path;
      // console.log(this.activeIndex2,"activeIndex2");

      next();
    });
  },
  mounted() {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    if (storedActiveIndex) {
      this.activeIndex2 = storedActiveIndex;
    }
  },

  beforeDestroy() {
    // 页面卸载时将高亮索引存储到 localStorage
    localStorage.setItem("activeIndex", this.activeIndex2);
  },
  methods: {
    handleCommand(command) {
      this.menuObj = command;
      this.$router.push({
        path: this.menuObj.router,
      });
      console.log(command);
    },
    handleSelect(index) {
      console.log(index, "indexxxxx");
      this.menuObj = this.menuList.find((item) => item.router == index);
      console.log(this.menuObj);
      // 当用户选择新的导航项时，更新 activeIndex2 并存储到 localStorage
      this.activeIndex2 = index;
      localStorage.setItem("activeIndex", this.activeIndex2);
    },
    getImgList() {
      this.loading = true;
      listImg(this.queryParams).then((response) => {
        this.imgList = response.rows;
        this.imgList.forEach((item) => {
          item.imgUrl = this.$baseImgUrl + item.imgUrl;
          if (item.imgPosition == "logo" && item.imgTitle == "logo1") {
            this.imgList = item;
          }
        });
        //console.log(this.imgList, "imgList");
        this.total = response.total;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.icon {
  display: flex;
  font-size: 25px;
  color: #000;
  font-weight: bold;
  align-items: center;
  margin: 0 40px;
}
.menu-val {
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  /* left: 0; */
  width: 100%;
  text-align: center;
}
</style>

<style lang="less" scoped>
.tabbar {
  .el-menu {
    display: flex;
    justify-content: center;
  }

  /deep/ .el-menu-item {
    text-align: center;
    width: 150px;
    padding: 10px;
    height: 90px;
    line-height: 70px;
    font-size: 20px;
    font-weight: bold;
  }

  /deep/ .el-menu-item.is-active {
    border-bottom: 4px solid #171a20;
  }

  .logo-box {
    display: flex;
    align-items: center;
    margin: 0 40px;
    img {
      height: 50px;
      width: 170px;
    }
  }
}
</style>
<style lang="less" scoped>
.tabbar-box {
  position: fixed;
  z-index: 5;
  width: 100%;
  .collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 60px;
    padding: 0 10px;
    background: #171a20;

    .logo-box {
      display: flex;
      align-items: center;
      width: 150px;
      img {
        height: 25px;
        width: 100px;
      }
    }
    .menu-title {
      color: #fff;
    }

    /deep/ .el-menu-item {
      text-align: left;
      height: 60px;
      line-height: 60px;
      font-weight: bold;
      border-bottom: 1px solid #eee;
    }

    /deep/ .el-menu--horizontal {
      flex-grow: 1;
    }

    /deep/ .el-submenu {
      width: 100%;
    }

    /deep/ .el-submenu__title {
      text-align: right;
    }

    /deep/ .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
      // border-bottom: 4px solid #fff;
      border-bottom-color: #171a20 !important;
    }
    /deep/ .el-menu--horizontal {
      position: relative;
      border-bottom-color: #171a20 !important;
    }
  }
}
</style>
