var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pic",class:_vm.isMobile ? 'mobile-pic' : ''},[_c('div',{class:_vm.isMobile ? 'mobile-group' : 'group'},[_c('div',{staticClass:"group-title"},[_vm._v("新闻咨询")]),_c('div',{staticClass:"group-content"},[_vm._v("NEWS INFORMATION")])])]),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"break",style:(_vm.isMobile ? 'width:100%' : '')},[_c('breadCrumb')],1),_c('div',{staticClass:"news"},[(!_vm.isMobile)?_c('div',{staticClass:"news-box"},[_c('el-row',{attrs:{"gutter":20}},_vm._l((_vm.newsList),function(item){return _c('el-col',{key:item.id,attrs:{"span":8}},[_c('img',{staticClass:"news-pic",attrs:{"src":item.newsImg,"alt":""}}),_c('div',[_c('h2',{staticClass:"news-title"},[_vm._v(_vm._s(item.newsTitle))]),_c('p',{staticClass:"news-content",domProps:{"innerHTML":_vm._s(item.newsDescribe)}}),_c('div',{staticClass:"news-group"},[_c('el-button',{staticClass:"news-more",attrs:{"round":""},on:{"click":function($event){return _vm.$router.push({
                      path: '/newsdetail/' + item.id,
                      query: { id: item.id },
                    })}}},[_vm._v("MORE>")]),_c('div',{staticClass:"news-time"},[_vm._v(_vm._s(item.createTime))])],1)])])}),1)],1):_c('div',{staticClass:"mobile-news-box"},_vm._l((_vm.newsList),function(item){return _c('div',{key:item.id,staticClass:"mobile-news-item"},[_c('img',{staticClass:"news-pic",attrs:{"src":item.newsImg,"alt":""}}),_c('div',[_c('h2',{staticClass:"news-title"},[_vm._v(_vm._s(item.newsTitle))]),_c('p',{staticClass:"news-content",domProps:{"innerHTML":_vm._s(item.newsDescribe)}}),_c('div',{staticClass:"news-group"},[_c('el-button',{staticClass:"news-more",attrs:{"round":""},on:{"click":function($event){return _vm.$router.push({
                    path: '/newsdetail/' + item.id,
                    query: { id: item.id },
                  })}}},[_vm._v("MORE>")]),_c('div',{staticClass:"news-time"},[_vm._v(_vm._s(item.createTime))])],1)])])}),0)]),_c('div',{staticClass:"pagination-box",style:(_vm.isMobile ? 'width:100%' : '')},[_c('pagiNation',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.queryParams.pageNum,"limit":_vm.queryParams.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.queryParams, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.queryParams, "pageSize", $event)},"pagination":_vm.getList}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }