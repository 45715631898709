<template>
  <div style="" class="newsdetail">
    <div
      style=""
      :class="isMobile ? 'mobile-newsdetail-box' : 'newsdetail-box'"
    >
      <!-- 左边 -->
      <div class="newsdetail-left">
        <!-- 头部标题 -->
        <div class="left-header" style="">
          <h2 class="header-title" style="">{{ newsList.newsTitle }}</h2>
          <div class="header-time" style="">{{ newsList.createTime }}</div>
        </div>
        <div class="content" v-html="newsList.newsDescribe"></div>
        <!-- 内容 -->
        <!-- <div >
                <p>3月19日，中国邮政储蓄银行公司金融部总经理吴之雄一行深入网思科技进行调研，与网思科技董事长共同探讨如何携手推动科技企业实现高质量发展。中国邮政储蓄银行公司金融部行业客户三处副处长王名扬，邮储银行广东省分行副行长夏春林、马天楠，广东省分行公司金融部副总经理倪君，广州市分行副行长杨丽，广州分行普惠金融事业部总经理廖萃扬、公司金融部副总经理廖益嘉，网思科技副总裁景霞和网思科技财务总监何颖欣等陪同调研。
</p>
               <div class="content-box" style="">
                <img class="content-img" src="@/assets/newsdetail1.jpg" alt="" style="">
               </div>
               <div class="introduction" style="">图为邮储银行公司金融部总经理吴之雄一行合影</div>
               <p>在全国两会热议科技创新与高质量发展的背景下，中国邮政储蓄银行（下文简称“邮储银行”）作为国有大型商业银行，始终将支持科技企业发展列为战略核心。其中，网思科技以其卓越的研发实力与广阔的市场前景，备受邮储银行的关注与青睐。

               </p>
               <p>吴之雄一行参观了网思科技的展厅，听取董事长关于企业如何运用人工智能和数字孪生等尖端技术赋能智能制造、无人驾驶运营的成果介绍。这些创新技术的应用不仅显著提升了客户的生产效率、大幅降低运营成本，而且还为企业的可持续发展注入了强大的动力。吴之雄对网思科技在新技术应用方面的突出成就表示了高度赞赏。

</p>        <div style="width: 100%;height: 400px;margin:0 auto;text-align: center; margin-top: 30px;">
                <img src="@/assets/newsdetail1.jpg" alt="" style="height: 400px;margin:0 auto;">
               </div>
               <div style="color:#333; margin: 0 auto; text-align: center;padding-top: 20px; font-size:14px">图为邮储银行公司金融部总经理吴之雄一行合影</div>
               <p>座谈会上，双方就如何进一步加强合作，共同推动网思科技的高质量发展进行了深入的交流。邮储银行团队在全面了解网思科技的经营状况、研发实力及市场前景后，对企业的创新能力和发展潜力给予了高度评价。

               </p>
               <p>吴之雄表示，邮储银行始终坚持以服务实体经济为己任，通过优化金融服务、创新金融产品、降低融资成本等方式，全力支持科技企业的创新发展，为网思科技等优秀企业提供更加精准、高效的金融服务。同时，邮储银行积极响应全国两会精神，不断探索金融科技创新，为企业提供更加高效、便捷的金融服务体验。</p>
            </div> -->
      </div>
      <!-- 右边区域 -->
      <!-- @click="$router.push({ path: '/newsdetail/' + item.id, query: { id: item.id } })" -->
      <div class="newsdetail-right" style="">
        <div class="right-header" style="">
          <h3 class="right-title" style="padding-bottom: 20px">相关推荐</h3>
          <div
            class="right-box"
            style=""
            v-for="(item, index) in news"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div class="right-box-left" style="">
              <img
                :src="item.newsImg"
                alt=""
                style=""
                class="right-box-img"
              />
            </div>
            <div class="right-box-right" style="">
              <div class="right-box-right-title" style="">
                {{ item.newsTitle }}
              </div>
              <div class="right-box-right-time" style="">
                {{ item.createTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNews, listNews } from "@/api/news";

export default {
  mounted() {},
  data() {
    return {
      newsList: [],
      news: [],
      // news1:[],
      //id:this.$route.query.id
      queryParams: {
        orderByColumn: "create_time",
        isAsc: "desc",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.getNewsbyId(), this.getList();
  },
  methods: {
    getNewsbyId() {
      const id = this.$route.params.id;
      // console.log(id);
      this.loading = true;

      getNews(id).then((response) => {
        this.newsList = response.data;
        //console.log(this.newsList, "newsllist");
        this.newsList.createTime = this.newsList.createTime.substr(0, 10);
        this.loading = false;
      });
    },

    /** 查询新闻列表 */
    getList() {
      this.loading = true;

      listNews(this.queryParams).then((response) => {
        this.news = response.rows;

        // console.log(this.newsList, "newsList");
        this.news.forEach((item) => {
          //item.newsImg = process.env.VUE_APP_BASE_API + item.newsImg;
          item.newsImg = this.$baseImgUrl + item.newsImg;
          // console.log(item.newsImg, "item.newsImg");

          item.createTime = item.createTime.substr(0, 10);
        });
        this.total = response.total;
        this.loading = false;
      });
    },

    goDetail(id) {
      //console.log(id,"id");
      if (this.newsList.id != id) {
        this.$router.push({ path: "/newsdetail/" + id, params: { id: id } });
      }

      this.getNewsbyId();
    },
    // godetail(id) {
    //   // 调用 $router.push 进行导航，并传递 id 作为参数
    //   this.$router.push({ path: "/newsdetail/" + id, params: { id: id } });

    //   // 如果需要执行其他逻辑，可以在这里添加
    // },
  },
};
</script>
<style lang="less" scoped>
.mobile-newsdetail-box {
  padding: 30px 10px;
  color: #000;
  .left-header {
    border-bottom: 1px solid #000;
    .header-time {
      padding: 10px 0;
    }
  }
  .right-box {
    display: flex;
    padding-bottom: 20px;
    .right-box-left {
      width: 130px;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right-box-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 10px;
      font-size: 14px;
      .right-box-right-time {
        color: #999;
      }
    }
  }
}
</style>
<style scoped lang="less">
p {
  color: #333;
  line-height: 2.5; /* 调整行间距 */
  font-size: 14px;
  margin-bottom: 10px; /* 可选：段落间距 */
  padding-top: 20px;
}
.newsdetail {
  width: 100%;
  background-color: #fff;
  .newsdetail-box {
    width: 85%;
    margin: 0 auto;
    display: flex;
    padding-top: 50px;
    height: 100%;
    padding-bottom: 100px;
    .newsdetail-left {
      //flex: 1;
      width: 970px;
      .left-header {
        height: 100px;
        border-bottom: 1px solid #000;
        .header-title {
          color: #333;
        }
        .header-time {
          color: #333;
          margin-top: 20px;
        }
      }

      .content-box {
        width: 100%;
        height: 400px;
        margin: 0 auto;
        text-align: center;
        margin-top: 30px;
        overflow: hidden;
        .content-img {
          width: 300px;
          height: 400px;
          margin: 0 auto;
        }
      }
      .introduction {
        color: #333;
        margin: 0 auto;
        text-align: center;
        padding-top: 20px;
        font-size: 14px;
      }
    }
    .newsdetail-right {
      flex: 1;
      width: 900px;
      padding-left: 100px;
      margin-left: 50px;
      padding-top: 60px;
      .right-header {
        width: 60%;
        .right-title {
          color: #333;
        }
        .right-box {
          display: flex;
          height: 90px;
          margin: 0 auto;
          margin-top: 20px;
          .right-box-left {
            flex: 1;
            .right-box-img {
              width: 120px;
              height: 90px;
            }
          }
          .right-box-right {
            flex: 2;
            margin-left: 10px;
            .right-box-right-title {
              color: #333;
              font-size: 14px;
            }
            .right-box-right-time {
              color: #333;
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
img {
  max-width: 100%; /* 限制图片最大宽度为其容器的宽度 */
  height: auto; /* 保持图片的原始纵横比 */
}
.content {
  color: #333;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  /deep/ img {
    max-width: 50%;
  }
}
</style>
