import { render, staticRenderFns } from "./joinUs.vue?vue&type=template&id=3f415c6c&scoped=true"
import script from "./joinUs.vue?vue&type=script&lang=js"
export * from "./joinUs.vue?vue&type=script&lang=js"
import style0 from "./joinUs.vue?vue&type=style&index=0&id=3f415c6c&prod&lang=less&scoped=true"
import style1 from "./joinUs.vue?vue&type=style&index=1&id=3f415c6c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f415c6c",
  null
  
)

export default component.exports