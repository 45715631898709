import { render, staticRenderFns } from "./newsDetail.vue?vue&type=template&id=439d3964&scoped=true"
import script from "./newsDetail.vue?vue&type=script&lang=js"
export * from "./newsDetail.vue?vue&type=script&lang=js"
import style0 from "./newsDetail.vue?vue&type=style&index=0&id=439d3964&prod&lang=less&scoped=true"
import style1 from "./newsDetail.vue?vue&type=style&index=1&id=439d3964&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "439d3964",
  null
  
)

export default component.exports