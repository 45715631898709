<template>
  <div class="navbar-box">
    <div class="navbar">
      <el-menu
        :router="true"
        :default-active="activeIndex2"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#171a20"
        text-color="hsla(0,0%,100%,.5)"
        active-text-color="#fff"
      >
        <div class="logo-box">
          <img
            :src="imgList.imgUrl"
            alt=""
            style="height: 90px;width: 230px;"
          />
        </div>
        <el-menu-item index="/">首页</el-menu-item>

        <!-- <el-menu-item index="/news" >新闻中心</el-menu-item>
           -->
        <el-submenu index="/news" popper-class="news">
          <template slot="title">新闻中心</template>
          <div class="news">
            <div class="news-box">
              <div class="news-content">
                <!-- <div  class="news-title1" style="flex: 1">公司咨询</div> -->
                <el-menu-item
                  index="/news"
                  class="news-title1"
                  style="color: #fff;"
                  @click="goToNews(0)"
                  >公司咨询</el-menu-item
                >

                <el-menu-item
                  index="/news"
                  style="color: #fff;"

                  class="news-title2"
                  @click="goToNews(1)"
                  >行业咨询</el-menu-item
                >
              </div>
            </div>
          </div>
        </el-submenu>
        <el-submenu index="/product" popper-class="product">
          <template slot="title">产品内容</template>
          <div class="product">
            <div class="product-box" style="">
              <div
                class="product-box-item"
                style=""
                v-for="item in menuList"
                :key="item.id"
              >
                <div >
                  
                    <h2 style="font-size: 20px;">{{ item.menuName }}</h2>
                  
                </div>
                <div class="product-box-item-content" style="">
                  <div
                    class="product-box-item-content-item"
                    style=""
                    v-for="item2 in item.children"
                    :key="item2.id"
                  >
                    <h3 style="font-size: 16px;margin-top: 10px;">{{ item2.menuName }}</h3>
                    <div style="width: 60px;margin-top: 10px; border-bottom: 1px solid skyblue;"></div>
                    <div
                      class="product-box-item-content-item2"
                      style=""
                      v-for="item3 in item2.children"
                      :key="item3.id"
                    >
                    
                      <el-menu-item
                        index="/product"
                        @click="goToProduct(item3.productId)"
                      >
                        <h3 style="font-size: 14px;color: #fff;margin-top: 10px;">{{ item3.menuName }}</h3></el-menu-item
                      >
                      <!-- <div>AI解决方案</div>
              <div>AI解决方案</div> -->
                    </div>
                  </div>
                  <!-- <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div> -->
                </div>
              </div>
              <!-- 右边服务体系 -->
              <!-- <div style="flex: 1;margin: 0 auto;text-align: center;background-color: skyblue; padding-top: 20px;">
          <div >
            <h2>数字化转型服务</h2>
          </div>
          <div style="display: flex;margin: 0 auto; text-align: left;">
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
          </div>
        </div> -->
            </div>
          </div>
        </el-submenu>
        <!-- <el-menu-item index="/system">服务体系</el-menu-item> -->
        <el-menu-item index="/about">关于我们</el-menu-item>
        <!-- <el-submenu index="/about" popper-class="about">
          <template slot="title">关于我们</template>
          <div class="about" style="width: 1500px;height:200px;padding-right: 200px;">
            <div
      style="
        width: 100%;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.85);
        margin: 0 auto;
        text-align: center;
        
      "
    >
      <div style="display: flex; width: 80%; margin: 0 auto; padding-top: 20px">
        <div style="flex: 1"><el-menu-item index='/about'>网思简介</el-menu-item></div>
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
      </div>
    </div>
          </div>
        </el-submenu> -->
        <!-- <el-menu-item index="/join">加入我们</el-menu-item> -->
        <el-submenu index="/join" popper-class="join">
          <template slot="title">加入我们</template>
          <div class="join" style="height: 130px;text-align: right;margin: 0 auto;margin-right: 300px;">
            <div class="join-box" >
              <div class="join-content" style="margin-top: 30px;">
                <div class="join-title1">
                  <div class="main-title" style="">
                    <el-menu-item style="font-style: 16px;color: #fff;padding-left: 600px;" index="/join" @click="goToJoin(1)"
                      >社会招聘</el-menu-item
                    >
                  </div>
                  <!-- <ul class="title-li" style="">
                    <li>技术体系</li>
                    <li>市场体系</li>
                    <li>运营体系</li>
                  </ul> -->
                </div>
                <div class="join-title2" style="flex: 1">
                  <div class="main-title" style="font-size: 30px">
                    <el-menu-item style="font-style: 16px;color: #fff;padding-left: 300px;" index="/join" @click="goToJoin(2)"
                      >校园招聘</el-menu-item
                    >
                  </div>
                  <!-- <ul class="title-li" style="margin-top: 20px">
                    <li>技术体系</li>
                    <li>市场体系</li>
                    <li>运营体系</li>
                  </ul> -->
                </div>
                <div class="join-title2" style="flex: 1">
                  <div class="main-title" style="font-size: 30px">
                    <el-menu-item style="font-style: 16px;color: #fff;" index="/joinus" 
                      >加盟方式</el-menu-item
                    >
                  </div>
                  <!-- <ul class="title-li" style="margin-top: 20px">
                    <li>麦维新面馆加盟</li>
                    <li>安全用电加盟</li> -->
                    <!-- <li>运营体系</li> -->
                  <!-- </ul> -->
                </div>
              </div>
            </div>
          </div>
        </el-submenu>
        <!-- <el-menu-item index="/login">登录/注册</el-menu-item> -->
        <span class="el-icon-bell icon"> 全国服务热线：400-808-5058</span>
      </el-menu>
    </div>
    <!-- 新闻中心 -->
    <!-- <div class="news-box">
      <div class="news-content">
        <div class="news-title1">公司咨询</div>
        <div class="news-title2">行业咨询</div>
      </div>
    </div> -->
    <!-- 产品与解决方案 -->
    <!-- <div
      style="
        width: 98%;
        
       
        margin: 0 auto;
        text-align: left;
        display: flex;
      "
    >
      <div
        style="
          flex: 1;
          margin: 0 auto;
          text-align: center;
          
          padding-top: 20px;
          display:none;
        "
        v-for="item in menuList"
        :key="item.id"
      >
        <div>
          <h2>{{ item.menuName }}</h2>
        </div>
        <div style="display: flex; margin: 0 auto; text-align: left">
          <div style="flex: 1" v-for="item2 in item.children" :key="item2.id">
            <h3>{{ item2.menuName }}</h3>
            <div v-for="item3 in item2.children" :key="item3.id">
              <div>{{ item3.menuName }}</div>
               <div>AI解决方案</div>
              <div>AI解决方案</div> -->
    <!-- </div>
          </div> -->
    <!-- <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div> -->
    <!-- </div>
      </div> -->
    <!-- 右边服务体系 -->
    <!-- <div style="flex: 1;margin: 0 auto;text-align: center;background-color: skyblue; padding-top: 20px;">
          <div >
            <h2>数字化转型服务</h2>
          </div>
          <div style="display: flex;margin: 0 auto; text-align: left;">
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
            <div style="flex: 1;">
              <h3>人工智能（AI）平台及解决方案</h3>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
              <div>AI解决方案</div>
            </div>
          </div>
        </div> -->
    <!-- </div> -->
    <!-- 关于我们 -->
    <!-- <div
      style="
        width: 100%;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.85);
        margin: 0 auto;
        text-align: center;
        display: none;
      "
    >
      <div style="display: flex; width: 80%; margin: 0 auto; padding-top: 20px">
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
        <div style="flex: 1">网思简介</div>
      </div>
    </div> -->
    <!-- 加入我们 -->
    <!-- <div class="join-box">
      <div class="join-content">
        <div class="join-title1">
          <div class="main-title">社会招聘</div>
          <ul class="title-li">
            <li>技术体系</li>
            <li>市场体系</li>
            <li>运营体系</li>
          </ul>
        </div>
        <div class="join-title2">
          <div class="main-title">校园招聘</div>
          <ul class="title-li">
            <li>技术体系</li>
            <li>市场体系</li>
            <li>运营体系</li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>
  
  <script>
import { listMenuVo } from "@/api/menu";
import { listImg, } from "@/api/img";

export default {
  // name: "navbar",
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "/",

      //showNews:'none',
      menuList: [],
      imgList:[],
      queryParams:{
        imgPosition:'logo'
      }
    };
  },
  created() {
    //console.log(this.menuList, "menuList");
    this.getList();
    this.getImgList();
  },
  mounted() {
    const storedActiveIndex = localStorage.getItem("activeIndex");
    if (storedActiveIndex) {
      this.activeIndex2 = storedActiveIndex;
    }
    //console.log(this.menuList,'menuList');
  },
  beforeDestroy() {
    // 页面卸载时将高亮索引存储到 localStorage
    localStorage.setItem("activeIndex", this.activeIndex2);
  },
  methods: {
    getImgList() {
      this.loading = true;
      listImg(this.queryParams).then(response => {
        this.imgList = response.rows;
        this.imgList.forEach((item)=>{
          item.imgUrl=this.$baseImgUrl+item.imgUrl;
          if(item.imgPosition=='logo'&&item.imgTitle=='logo1'){
            this.imgList=item
          }
        })
        //console.log(this.imgList, "imgList");
        this.total = response.total;
        this.loading = false;
      });
    },
    handleSelect(index) {
      // 当用户选择新的导航项时，更新 activeIndex2 并存储到 localStorage
      this.activeIndex2 = index;
      localStorage.setItem("activeIndex", this.activeIndex2);
    },
    goToNews(newsKind) {
      this.$router.push({ name: "news", params: { newsKind: newsKind } });
    },
    goToJoin(jobType) {
      this.$router.push({ name: "join", params: { jobType: jobType } });
    },
    // goToProduct(id) {
    //   console.log(id, "id");
    //    this.$router.push({ name: "product", params: { id: id } });
    // },
    goToProduct(productId) {
      //console.log(productId, "productId");
      this.$router.push({ name: "product", params: { productId: productId } });
    },
    getList() {
      this.loading = true;

      listMenuVo().then((response) => {
        this.menuList = response.data;
        //console.log(this.menuList, "menuList");
      });
    },
  },
};
</script>
  
  
  
  <style lang="less" scoped>
.navbar-box {
  .el-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 90px;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: solid 1px #252a34;
  }
  .el-menu-item {
    text-align: center;
    width: 150px;
    padding: 10px;
    font-size: 14px;
  }
  .el-submenu {
    text-align: center;
    width: 150px;
    font-size: 14px;
  }
  .icon {
    display: flex;
    font-size: 16px;
    color: #fff;
    align-items: center;
    margin-left: 50px;
  }
  .logo-box {
    display: flex;
    align-items: center;
    margin-right: 40px;
    img {
      height: 25px;
      width: 170px;
    }
  }
  .news-box {
    width: 100%;
    height: 106px;

    display: none;
    .news-content {
      width: 60%;
      //background-color: pink;
      margin: 0 auto;
      display: flex;

      .news-title1,
      .news-title2 {
        flex: 1;
        margin: 50px auto;
        text-align: center;
        font-size: 16px;
      }
    }
  }
  .join {
    //width: 1000px;
    //height: 600px;
    
    .join-box {
      width: 100%;
      height: 130px;
      //background-color: rgba(0, 0, 0, 0.85);
      margin: 0 auto;
      text-align: center;
      display: none;
      .join-content {
        width: 60%;
        //background-color: pink;
        margin: 0 auto;
        display: flex;
        .join-title1,
        .join-title2 {
          flex: 1;
          margin: 20px auto;
          text-align: center;
          .title-li {
            list-style-type: none;
          }
        }
        .main-title {
          font-size: 16px;
          //font-weight: 600;
          margin-bottom: 20px;
        }
      }
    }
  }
  .title-li li {
    margin-top: 20px;
    font-size: 14px;
  }
}
li {
  list-style: none;
}
.news {
  width: 1500px;
  padding-right: 500px;
  color: #fff;
  //background-color: #171a20;
  height: 106px;
  margin: 0 auto;
  text-align: right;
}
/deep/ .el-submenu__title {
  padding: 10px 10px;
  font-size: 14px;
}
.text-color {
  color: #fff;
}
.news-content {
  display: flex;
  margin: 0 auto;
  padding-top: 35px;
}
.news-title1 {

  flex: 1;
  font-size: 16px;
 
}
.news-title2 {
  flex: 1;
  font-size: 16px;
  
}
.product {
  display: flex;
  //background-color: #fff;
  //background-image: url("../assets/product.png");
  margin: 0 auto;
  width: 1700px;
  text-align: center;
  height: 300px;
  padding-right: 200px;
}
.product-box {
  width: 98%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  //background-color: #171a20;
}
.product-box-item {
  
  margin: 0 auto;
  text-align: center;

  padding-top: 20px;
}
.product-box-item-content {
  display: flex;
  margin: 0 auto;
  text-align: left;
  margin-top: 20px;
}
.product-box-item-content-item {
  flex: 1;
  margin-left: 60px;
  width: 150px;
}
.product-box-item-content-item2 {
  margin-top: 10px;
}
.join {
  width: 1500px;
  padding-right: 300px;
  margin: 0 auto;
  text-align: center;
  height: 200px;
}
.join-content {
  display: flex;
}
.join-title1 {
  flex: 1;
}
.main-title {
  font-size: 30px;
}
.title-li {
  margin-top: 20px;
}
.element.style{
  color: #fff;
}
.header .header-pc .nav-r .li-1 .nav-layer .nav-container {
    display: none;
    padding: 1.82rem 0 2.27rem;
    background-color: rgba(0, 0, 0, 0.85);
}

</style>