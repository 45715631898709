<!--
 * @Description: 
 * @Date: 2024-04-24 17:29:39
 * @LastEditTime: 2024-04-28 11:52:10
-->
<template>
  <div id="app">
    <navbar v-if="!isMobile"></navbar>
    <mobileNav v-else></mobileNav>
    <router-view :style="isMobile ? 'padding-top:60px' : ''"/>
    <el-backtop :bottom="100">
      <div class="backTop" >
        <i class="el-icon-upload2"></i>
      </div>
    </el-backtop>
    <!-- <div class="advertisement"></div> -->
    <pagefnal></pagefnal>
  </div>
</template>
<script>
import pagefnal from "@/components/pageFnal.vue";
import navbar from "@/components/NavBar.vue";
import mobileNav from '@/components/mobileNav.vue'
export default {
  components: {
    pagefnal,
    navbar,
    mobileNav
  },
  data() {
    return {
      num: 10
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>
<style lang="less">
.el-scrollbar {
    > .el-scrollbar__bar {
        opacity: 1 !important;
    }
}
@keyframes advertisement {
  0% {
    transform: scale(1);
  }

  50% {
   transform: scale(1.5);
  }

  100% {
   transform: scale(1);
  }
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #171a20;
  color: #fff;
}
.backTop {
  height: 100%;
  width: 100%;
  border: 2px solid skyblue;
  background: skyblue;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 50%;
  line-height: 40px;
  color: #fff;
}
.advertisement {
  animation: advertisement 2s infinite;
  position: fixed;
  right: 30px;
  bottom: 440px;
  cursor: pointer;
  z-index: 9;
  // background: url(https://img.odinnft.cn/pc/synthesis/entrance.png) no-repeat
  //   50%;
  width: 130px;
  height: 130px;
  background-size: cover;
}
</style>
