<template>
  <div class="system">
    <div class="pic" :class="isMobile ? 'mobile-pic' : ''">
      <div :class="isMobile ? 'mobile-group' : 'group'">
        <div class="group-title">服务体系</div>
        <div class="group-content">SERVICE</div>
      </div>
    </div>
    <div :class="isMobile ? 'mobile-break' : 'break'">
      <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
        <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <breadCrumb></breadCrumb>
    </div>
    <div style="" class="introduction">
      <div v-if="!isMobile" class="title-box">
        <div id="title" class="title">简介</div>
        <div class="title-active"></div>
        <div class="title-eng">INTRODUCTION</div>
      </div>
      <div v-else style="padding-bottom: 40px">
        <div class="com-title" :style="isMobile ? 'padding:0' : ''">
          <h3 class="title-ch">
            简介
            <p class="title-en">INTRODUCTION</p>
          </h3>
        </div>
      </div>
      <div :class="isMobile ? 'mobile-bgimg' : 'bgimg'" style="">
        <div class="introduction-content" style="">
          <h2>服务体系</h2>
          <p class="introduction-content-p" style="">
            在大力发展数字经济、企业信息化转型之际，网思科技凭借多年来聚焦于新一代信息技术、数字化转型等领域的技术与商业模式的创新应用，对千行百业客户的业务流程、技术架构和应用环境有了深刻的理解。网思科技有能力满足客户在网络优化、运营维护和信息安全防护等方面的需求，为客户提供安全、稳定、合规、持续的信息技术服务。
          </p>
        </div>
      </div>
    </div>
    <div style="" class="level">
      <div v-if="!isMobile" class="title-box">
        <div id="title" class="title">服务级别</div>
        <div class="title-active"></div>
        <div class="title-eng">SERVICE LEVEL</div>
      </div>
      <div v-else>
        <div class="com-title" :style="isMobile ? 'padding:0' : ''">
          <h3 class="title-ch">
            服务级别
            <p class="title-en">SERVICE LEVEL</p>
          </h3>
        </div>
      </div>
      <div class="level-title" style="">
        网思科技提供多种类型的服务方案，以满足客户不同的服务需求
      </div>
      <div
        class="level-content"
        :class="isMobile ? 'mobile-level-content' : ''"
        style=""
      >
        <div class="level-item">
          <img
            class="level-item-img"
            src="@/assets/service1.png"
            alt=""
            style=""
          />
          <h3 class="level-item-title" style="">标准服务</h3>
          <div
            style="
              border-bottom: 1px solid skyblue;
              width: 90px;
              height: 10px;
              margin: 0 auto;
            "
          ></div>
          <li class="level-item-li" style="">5天*8小时在线咨询保障服务</li>
        </div>
        <div class="level-item">
          <img
            class="level-item-img"
            src="@/assets/service2.png"
            alt=""
            style=""
          />
          <h3 class="level-item-title" style="">标准服务</h3>
          <div
            style="
              border-bottom: 1px solid skyblue;
              width: 90px;
              height: 10px;
              margin: 0 auto;
            "
          ></div>
          <li class="level-item-li" style="">5天*8小时在线咨询保障服务</li>
        </div>
        <div class="level-item">
          <img
            class="level-item-img"
            src="@/assets/service3.png"
            alt=""
            style=""
          />
          <h3 class="level-item-title" style="">标准服务</h3>
          <div
            style="
              border-bottom: 1px solid skyblue;
              width: 90px;
              height: 10px;
              margin: 0 auto;
            "
          ></div>
          <li class="level-item-li" style="">5天*8小时在线咨询保障服务</li>
        </div>
        <div class="level-item">
          <img
            class="level-item-img"
            src="@/assets/service1.png"
            alt=""
            style=""
          />
          <h3 class="level-item-title" style="">标准服务</h3>
          <div
            style="
              border-bottom: 1px solid skyblue;
              width: 90px;
              height: 10px;
              margin: 0 auto;
            "
          ></div>
          <li class="level-item-li" style="">5天*8小时在线咨询保障服务</li>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" style="" class="secure">
      <div style="" class="secure-box">
        <div style="" class="secure-item">
          <img
            src="@/assets/service5.png"
            alt=""
            style=""
            class="secure-item-img"
          />
        </div>
        <div class="secure-item2" style="">
          <h2 class="secure-item2-title" style="">安全服务</h2>
          <div class="secure-item2-title2" style="">SECURITY SERVICE</div>
          <div class="secure-item2-content" style="">
            覆盖安全方案、安全服务、安全运营的完整业务生态，为各大行业客户提供一站式的信息安全整体解决方案
          </div>
          <div class="secure-item3" style="">
            <el-row :gutter="20">
              <el-col :span="6" style="" class="secure-item3-content"
                ><el-button
                  type="primary"
                  plain
                  style=""
                  class="secure-item3-content-btn"
                  ><i class="el-icon-star-on secure-item3-i" style=""></i
                  >风险评估服务</el-button
                ></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobile-secure">
      <img class="mobile-secure-img" src="@/assets/service5.png" alt="" />
      <div class="mobile-secure-title">
        <h3 class="title-ch">安全服务</h3>
        <div class="title-en">SECURITY SERVICE</div>
      </div>
      <div class="mobile-secure-introduction">
        覆盖安全方案、安全服务、安全运营的完整业务生态，为各大行业客户提供一站式的信息安全整体解决方案
      </div>
      <div class="mobile-secure-btn">
        <div class="btn" v-for="item in 6">风险评估服务</div>
      </div>
    </div>
    <div v-if="!isMobile" style="" class="operation">
      <div class="operation-box" style="">
        <div style="" class="opration-item">
          <h2 class="operation-item-title" style="">运维服务</h2>
          <div class="operation-item-title2" style="">
            OPERATION AND MAINTENANCE
          </div>
          <div class="operation-content" style="">
            公司和国内外知名软硬件企业紧密合作，为企业级提供快速、有效、专业的MySQL运维支撑服务
          </div>
          <div class="operation-btn" style="">
            <el-row :gutter="20">
              <el-col :span="6" style="" class="operation-btm-item"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
            </el-row>
          </div>
        </div>
        <div style="" class="operation-item2">
          <img
            class="operation-item2-img"
            src="@/assets/service6.png"
            alt=""
            style=""
          />
        </div>
      </div>
    </div>
    <div v-else class="mobile-operation">
      <div class="operation-img-box">
        <img class="operation-img" src="@/assets/service6.png" alt="" />
      </div>
      <div class="operation-introduction-box">
        <div class="mobile-secure-title">
          <h3 class="title-ch">运维服务</h3>
          <div class="title-en">OPERATION AND MAINTENANCE</div>
        </div>
        <div class="mobile-secure-introduction">
          公司和国内外知名软硬件企业紧密合作，为企业级提供快速、有效、专业的MySQL运维支撑服务
        </div>
        <div class="mobile-secure-btn">
          <div class="btn" v-for="item in 6">风险评估服务</div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" style="" class="secure">
      <div style="" class="secure-box">
        <div style="" class="secure-item">
          <img
            src="@/assets/service7.png"
            alt=""
            style=""
            class="secure-item-img"
          />
        </div>
        <div class="secure-item2" style="">
          <h2 class="secure-item2-title" style="">安全服务</h2>
          <div class="secure-item2-title2" style="">SECURITY SERVICE</div>
          <div class="secure-item2-content" style="">
            覆盖安全方案、安全服务、安全运营的完整业务生态，为各大行业客户提供一站式的信息安全整体解决方案
          </div>
          <div class="secure-item3" style="">
            <el-row :gutter="20">
              <el-col :span="6" style="" class="secure-item3-content"
                ><el-button
                  type="primary"
                  plain
                  style=""
                  class="secure-item3-content-btn"
                  ><i class="el-icon-star-on secure-item3-i" style=""></i
                  >风险评估服务</el-button
                ></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
              <el-col :span="6" style="margin-top: 20px"
                ><el-button type="primary" plain>主要按钮</el-button></el-col
              >
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobile-secure">
      <img class="mobile-secure-img" src="@/assets/service7.png" alt="" />
      <div class="mobile-secure-title">
        <h3 class="title-ch">技术咨询服务</h3>
        <div class="title-en">CONSULTING SERVICE</div>
      </div>
      <div class="mobile-secure-introduction">
        为客户提供从开源软件治理到企业数字化转型的管理咨询服务，帮助客户提升战略管理能力
      </div>
      <div class="mobile-secure-btn">
        <div class="btn" v-for="item in 6">IT发展规划咨询设计</div>
      </div>
    </div>
  </div>
</template>

<script>
import breadCrumb from "@/components/breadCrumb.vue";

export default {
  compoments: {
    breadCrumb,
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.com-title {
  padding: 3.55rem 0 3.68rem;
  text-align: center;
  .title-ch {
    position: relative;
    font-size: 1.64rem;
    color: #000;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.64rem;
      transform: translateX(-50%);
      width: 2.73rem;
      height: 0.09rem;
      background-color: #0096a3;
    }
  }
  .title-en {
    position: absolute;
    left: 50%;
    top: 0.91rem;
    transform: translateX(-50%);
    color: rgba(153, 153, 153, 0.15);
    z-index: 1;
    font-size: 1.2rem;
    white-space: nowrap;
    text-indent: 0;
  }
}
.mobile-pic {
  height: 400px !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .mobile-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .group-title {
      font-size: 50px;
    }
  }
}
.mobile-break {
  padding: 20px 0;
}
.mobile-bgimg {
  background: url(@/assets/system1.png) no-repeat right bottom;
  background-size: cover;
  .introduction-content {
    padding: 20px;
    font-size: 12px;
  }
}
.mobile-level-content {
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 !important;
  .level-item {
    width: 45%;
    margin: 0 0 20px 10px !important;
    height: 100% !important;
    .level-item-img {
      height: 100px;
      padding: 0 !important;
    }
  }
}
.mobile-secure {
  padding: 0 10px;
  color: #000;
  .mobile-secure-img {
    width: 100%;
  }
  .mobile-secure-title {
    padding: 10px 0;
    .title-ch {
      font-size: 17px;
    }
    .title-en {
      padding-top: 10px;
      color: #b3b3b3;
      font-size: 12px;
    }
  }
  .mobile-secure-introduction {
    font-size: 12px;
    color: #666;
  }
  .mobile-secure-btn {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    .btn {
      border: solid 0.05rem #02acb9;
      margin-right: 0.45rem;
      font-size: 14px;
      margin-bottom: 10px;
      padding: 5px;
    }
  }
}
.mobile-operation {
  position: relative;
  height: 500px;
  .operation-img-box {
    position: relative;
    z-index: 2;
    text-align: center;

    .operation-img {
      width: 90%;
    }
  }
  .operation-introduction-box {
    background: #232b44;
    position: absolute;
    top: 25%;
    padding: 100px 10px 0;
    .mobile-secure-title {
      padding: 10px 0;
      .title-ch {
        font-size: 17px;
      }
      .title-en {
        padding-top: 10px;
        color: #b3b3b3;
        font-size: 12px;
      }
    }
    .mobile-secure-introduction {
      font-size: 12px;
      color: #fff;
    }
    .mobile-secure-btn {
      display: flex;
      flex-wrap: wrap;
      padding: 30px 0;
      .btn {
        border: solid 0.05rem #fff;
        margin-right: 0.45rem;
        font-size: 14px;
        margin-bottom: 10px;
        padding: 5px;
      }
    }
  }
}
</style>
<style scoped lang="less">
.system {
  width: 100%;
  background-color: #fff;
}
.title-active {
  border-bottom: 1px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}
.pic {
  width: 100%;
  height: 600px;
  background-image: url(@/assets/system.png);
  .group {
    margin: 0 auto;
    padding-top: 200px;
    text-align: center;
    width: 100%;
    .group-title {
      font-size: 50px;
    }
    .group-content {
      margin-top: 20px;
    }
  }
}
.break {
  width: 75%;
  text-align: left;
  margin: 30px auto;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #333;
}
.title::before {
  content: "";
  background-image: url("@/assets/before.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-right: 20px;
}
.title::after {
  content: "";
  background-image: url("@/assets/after.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-left: 20px;
}
.title-eng {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #999;
}

.introduction {
  width: 100%;
  margin: 50px auto;
  text-align: left;
  .bgimg {
    background-image: url("@/assets/system1.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
    width: 100%;
    .introduction-content {
      width: 60%;
      margin: 0px 0 30px 50px;
      padding-top: 80px;
    }
    .introduction-content-p {
      margin-top: 20px;
    }
  }
}
.level {
  width: 80%;
  margin: 50px auto;
  text-align: left;
  .level-title {
    width: 100%;
    margin: 50px auto;
    text-align: center;
    color: #333;
    font-size: 20px;
  }
  .level-content {
    width: 100%;
    margin: 50px auto;
    text-align: center;
    display: flex;
    .level-item {
      flex: 1;
      background-color: #f9f9f9;
      margin-left: 10px;
      height: 400px;
      .level-item-img {
        padding-top: 100px;
        width: 100px;
        height: 100px;
      }
      .level-item-title {
        margin-top: 30px;
        color: #333;
      }
      .level-item-li {
        margin-top: 20px;
        color: #333;
      }
    }
  }
}
.secure {
  background-color: #f5f8fa;
  .secure-box {
    width: 80%;
    margin: 0 auto;
    text-align: left;
    display: flex;
    .secure-item {
      flex: 1;
      .secure-item-img {
        width: 574px;
        height: 363px;
      }
    }
    .secure-item2 {
      flex: 1;
      margin-left: -200px;
      .secure-item2-title {
        margin-top: 60px;
        color: #333;
      }
      .secure-item2-title2 {
        font-size: 12px;
        color: #ccc;
        margin-top: 10px;
      }
      .secure-item2-content {
        margin-top: 30px;
        font-size: 14px;
        color: #333;
      }
    }
    .secure-item3 {
      width: 100%;
      margin: 50px auto;
      text-align: left;
      .secure-item3-content {
        margin-top: 20px;
      }
      .secure-item3-content-btn {
        border: 1px solid #02acb9;
      }
      .secure-item3-i {
        color: #02acb9;
        padding-right: 5px;
      }
    }
  }
}
.operation {
  background-color: #f5f8fa;
  .operation-box {
    width: 80%;
    margin: 50px auto;
    text-align: left;
    display: flex;
    .opration-item {
      flex: 1;
      .operation-item-title {
        margin-top: 60px;
        color: #333;
      }
      .operation-item-title2 {
        font-size: 12px;
        color: #ccc;
        margin-top: 10px;
      }
      .operation-content {
        margin-top: 30px;
        font-size: 14px;
        color: #333;
      }
    }
    .operation-btn {
      width: 100%;
      margin: 50px auto;
      text-align: left;
      .operation-btm-item {
        margin-top: 20px;
      }
    }
    .operation-item2 {
      flex: 1;
      .operation-item2-img {
        width: 574px;
        height: 363px;
      }
    }
  }
}
p {
  line-height: 2.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
}
</style>
