import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import zmSwiper from '@/components/zmSwiper.vue'
import pagiNation from '@/components/pagiNation.vue'
import breadCrumb from '@/components/breadCrumb.vue'

// http://localhost:81/dev-api/biz/news/list?pageNum=1&pageSize=10
//Vue.prototype.$baseImgUrl='http://47.96.146.220:3389/prod-api'
Vue.prototype.$baseImgUrl=''
//Vue.prototype.$baseImgUrl='https://www.heyton.cn/prod-api'


// Vue.prototype.$uploadUrl ='http://192.168.2.246:8096/common/upload';
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('zmSwiper', zmSwiper)
Vue.component('pagiNation', pagiNation)
Vue.component('breadCrumb', breadCrumb)

new Vue({
  router,
  store,
  created() {
    // 在应用初始化时检测设备类型
    this.$store.dispatch('detectDevice');
  },
  render: h => h(App)
}).$mount('#app')
