<template>
  <div>
    <div class="pic" :class="isMobile ? 'mobile-pic' : ''">
      <div :class="isMobile ? 'mobile-group' : 'group'">
        <div class="group-title">新闻咨询</div>
        <div class="group-content">NEWS INFORMATION</div>
      </div>
    </div>
    <div class="main-content">
      <div class="break" :style="isMobile ? 'width:100%' : ''">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>活动管理</el-breadcrumb-item>
          <el-breadcrumb-item>活动列表</el-breadcrumb-item>
          <el-breadcrumb-item>活动详情</el-breadcrumb-item>
        </el-breadcrumb> -->
        <breadCrumb></breadCrumb>
      </div>

      <div class="news">
        <div v-if="!isMobile" class="news-box">
          <el-row :gutter="20">
            <el-col :span="8" v-for="item in newsList" :key="item.id">
              <img :src="item.newsImg" class="news-pic" alt="" />
              <div>
                <h2 class="news-title">{{ item.newsTitle }}</h2>

                <p class="news-content" v-html="item.newsDescribe"></p>

                <div class="news-group">
                  <!-- '/newsdetail/' + item.id -->
                  <el-button
                    round
                    class="news-more"
                    @click="
                      $router.push({
                        path: '/newsdetail/' + item.id,
                        query: { id: item.id },
                      })
                    "
                    >MORE></el-button
                  >
                  <div class="news-time">{{ item.createTime }}</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else class="mobile-news-box">
          <div class="mobile-news-item" v-for="item in newsList" :key="item.id">
            <img :src="item.newsImg" class="news-pic" alt="" />
            <div>
              <h2 class="news-title">{{ item.newsTitle }}</h2>

              <p class="news-content" v-html="item.newsDescribe"></p>

              <div class="news-group">
                <!-- '/newsdetail/' + item.id -->
                <el-button
                  round
                  class="news-more"
                  @click="
                    $router.push({
                      path: '/newsdetail/' + item.id,
                      query: { id: item.id },
                    })
                  "
                  >MORE></el-button
                >
                <div class="news-time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-box" :style="isMobile ? 'width:100%' : ''">
        <pagiNation
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pagiNation from "@/components/pagiNation.vue";
import breadCrumb from "@/components/breadCrumb.vue";
import { listNews } from "@/api/news";

export default {
  components: {
    pagiNation,
    breadCrumb,
  },
  data() {
    return {
      newsList: [],
      total: 0,
      queryParams: {
        newsKind: 1,
        pageNum: 1,
        pageSize: 9,
        orderByColumn: "create_time",
        isAsc: "desc",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.queryParams.newsKind = this.$route.params.newsKind;

    this.getList();
  },
  watch: {
    "$route.params.newsKind": function (newVal) {
      // 当newsKind变化时，更新数据
      this.queryParams.newsKind = newVal;
      this.getList(); // 根据新的newsKind加载数据
    },
  },
  methods: {
    /** 查询新闻列表 */
    getList() {
      this.loading = true;

      listNews(this.queryParams).then((response) => {
        this.newsList = response.rows;
        //console.log(this.newsList, "newsList");
        this.newsList.forEach((item) => {
          //item.newsImg = process.env.VUE_APP_BASE_API + item.newsImg;
          item.newsImg = this.$baseImgUrl + item.newsImg;
          // console.log(item.newsImg, "item.newsImg");

          item.createTime = item.createTime.substr(0, 10);
        });
        this.total = response.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-pic {
  height: 400px !important;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .mobile-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .group-title {
      font-size: 50px;
    }
  }
}

</style>
<style scoped lang="less">
.pic {
  width: 100%;
  height: 600px;
  background-image: url(@/assets/news.png);
  .group {
    margin: 0 auto;
    padding-top: 200px;
    text-align: center;
    width: 100%;
    .group-title {
      font-size: 50px;
    }
    .group-content {
      margin-top: 20px;
    }
  }
}
.main-content {
  width: 100%;
  background-color: #fff;
  .break {
    width: 75%;
    text-align: left;
    margin: 0 auto;
    padding-top: 30px;
  }
  .news {
    width: 100%;
    text-align: center;
    margin: 80px auto;
    .news-box {
      width: 70%;
      margin: 0 auto;
      .news-pic {
        width: 100%;
        height: 280px;
      }
      .news-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 20px 0;
        font-size: 20px;
        color: #000;
      }
      .news-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 20px;
        color: #666;
      }
      .news-group {
        position: relative;
        margin-bottom: 50px;
        height: 30px;
        .news-more {
          position: absolute;
          bottom: -5;
          left: 0;
          color: #999;
        }
        .news-time {
          position: absolute;
          bottom: 0;
          right: 0;
          color: #999;
        }
      }
    }
    .mobile-news-box {
      .mobile-news-item {
        padding: 0 10px;
        .news-pic {
          width: 100%;
          height: 200px;
        }
        .news-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 10px 0;
          font-size: 16px;
          color: #000;
          text-align: start;
        }
        .news-content {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 20px;
          color: #666;
        }
        .news-group {
          position: relative;
          margin-bottom: 50px;
          height: 30px;
          .news-more {
            position: absolute;
            bottom: -5;
            left: 0;
            color: #999;
          }
          .news-time {
            position: absolute;
            bottom: 0;
            right: 0;
            color: #999;
          }
        }
      }
    }
  }
}
p {
  color: #333;
  line-height: 1.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
}
.pagination-box {
  width: 60%;
  margin: 0 auto;
  text-align: center;
}
</style>
