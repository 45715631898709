<template>
  <div>
    <div style="" class="newsdetail">
    
    <div style="" class="newsdetail-box" >
        <!-- 左边 -->
        <div class="newsdetail-left" style="" >
            <!-- 头部标题 -->
            <div class="left-header" style="">
                <h2 class="header-title" style="">{{jobList.jobName}}</h2>
                <div class="header-time" style="">{{jobList.createTime}}</div>
            </div>
            <div v-html="jobList.jobDescribe" style="color:#333;padding-top: 30px;" class="p"></div>
            <!-- <div class="content" v-html="newsList.newsDescribe">
                
            </div> -->
            <!-- 内容 -->
            <!-- <div >
                <p>3月19日，中国邮政储蓄银行公司金融部总经理吴之雄一行深入网思科技进行调研，与网思科技董事长共同探讨如何携手推动科技企业实现高质量发展。中国邮政储蓄银行公司金融部行业客户三处副处长王名扬，邮储银行广东省分行副行长夏春林、马天楠，广东省分行公司金融部副总经理倪君，广州市分行副行长杨丽，广州分行普惠金融事业部总经理廖萃扬、公司金融部副总经理廖益嘉，网思科技副总裁景霞和网思科技财务总监何颖欣等陪同调研。
</p>
               <div class="content-box" style="">
                <img class="content-img" src="@/assets/newsdetail1.jpg" alt="" style="">
               </div>
               <div class="introduction" style="">图为邮储银行公司金融部总经理吴之雄一行合影</div>
               <p>在全国两会热议科技创新与高质量发展的背景下，中国邮政储蓄银行（下文简称“邮储银行”）作为国有大型商业银行，始终将支持科技企业发展列为战略核心。其中，网思科技以其卓越的研发实力与广阔的市场前景，备受邮储银行的关注与青睐。

               </p>
               <p>吴之雄一行参观了网思科技的展厅，听取董事长关于企业如何运用人工智能和数字孪生等尖端技术赋能智能制造、无人驾驶运营的成果介绍。这些创新技术的应用不仅显著提升了客户的生产效率、大幅降低运营成本，而且还为企业的可持续发展注入了强大的动力。吴之雄对网思科技在新技术应用方面的突出成就表示了高度赞赏。

</p>        <div style="width: 100%;height: 400px;margin:0 auto;text-align: center; margin-top: 30px;">
                <img src="@/assets/newsdetail1.jpg" alt="" style="height: 400px;margin:0 auto;">
               </div>
               <div style="color:#333; margin: 0 auto; text-align: center;padding-top: 20px; font-size:14px">图为邮储银行公司金融部总经理吴之雄一行合影</div>
               <p>座谈会上，双方就如何进一步加强合作，共同推动网思科技的高质量发展进行了深入的交流。邮储银行团队在全面了解网思科技的经营状况、研发实力及市场前景后，对企业的创新能力和发展潜力给予了高度评价。

               </p>
               <p>吴之雄表示，邮储银行始终坚持以服务实体经济为己任，通过优化金融服务、创新金融产品、降低融资成本等方式，全力支持科技企业的创新发展，为网思科技等优秀企业提供更加精准、高效的金融服务。同时，邮储银行积极响应全国两会精神，不断探索金融科技创新，为企业提供更加高效、便捷的金融服务体验。</p>
            </div> -->
        </div>
        <!-- 右边区域 -->
        <!-- <div class="newsdetail-right" style=";">
        <div class="right-header" style="">
            <h3 class="right-title" style="">相关推荐</h3>
            <div class="right-box" style="">
                <div class="right-box-left" style=""><img src="@/assets/news1.jpg" alt="" style="" class="right-box-img"></div>
                <div class="right-box-right" style="">
                    <div class="right-box-right-title" style="">中国邮政储蓄银行总行公司部总经理吴之雄一行调研网思科技</div>
                    <div class="right-box-right-time" style="">2024-03-021</div>
                </div>
            </div>
            <div style="display:flex; height: 90px;margin: 0 auto; margin-top: 20px;">
                <div style="flex:1;"><img src="@/assets/news1.jpg" alt="" style="height: 90px;"></div>
                <div style="flex:2;margin-left: 10px;">
                    <div style="color:#333;font-size: 14px">中国邮政储蓄银行总行公司部总经理吴之雄一行调研网思科技</div>
                    <div style="color:#333;font-size: 14px;margin-top: 10px;">2024-03-021</div>
                </div>
            </div>
            
        </div>
        </div> -->
    </div>
  </div>
  </div>
</template>

<script>
import { getJob } from "@/api/job";

export default {
    data(){
        return{
            queryParams: {
                id: this.$route.params.id,
                
            },
            jobList:[]
        }
    },
    created(){
        this.getList();
        //console.log(this.$route.params.id,'this.$route.query.id')
    },
    methods:{
        getList() {  
      this.loading = true;  
      getJob(this.queryParams.id).then((response) => {  
        this.jobList = response.data;  
       this.jobList.createTime = this.jobList.createTime.substring(0,10);
       // console.log(this.jobList,'this.jobList')
        this.loading = false;  
      });
    },  
    }

}
</script>

<style scoped lang='less'>
 .p {
  color: #333;
  line-height: 2.5; /* 调整行间距 */
  font-size: 14px;
  margin-bottom: 10px; /* 可选：段落间距 */
  padding-top: 20px;
}
.newsdetail{
    width:100%;background-color: #fff;
    .newsdetail-box{
        width: 80%; margin: 0 auto; display:flex;padding-top: 50px;height: 100%; padding-bottom: 100px;
        .newsdetail-left{
            
            flex:2;
            .left-header{
                height: 100px;border-bottom: 1px solid #000;
                .header-title{
                    color:#333
                }
                .header-time{
                    color:#333;margin-top: 20px;
                }
            }
            
            .content-box{
                width: 100%;height: 400px;margin:0 auto;text-align: center; margin-top: 30px;
                .content-img{
                    height: 400px;margin:0 auto;
                }
            }
            .introduction{
                color:#333; margin: 0 auto; text-align: center;padding-top: 20px; font-size:14px
            }
        }
        .newsdetail-right{
            flex:1;width: 100%;padding-left: 100px;padding-top: 60px;
            .right-header{
                width: 60%;
                .right-title{
                    color:#333;
                }
                .right-box{
                    display:flex; height: 90px;margin: 0 auto; margin-top: 20px;
                    .right-box-left{
                        flex:1;
                        .right-box-img{
                            height: 90px;
                        }
                    }
                    .right-box-right{
                        flex:2;margin-left: 10px;
                        .right-box-right-title{
                            color:#333;font-size: 14px;
                        }
                        .right-box-right-time{
                            color:#333;font-size: 14px;margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}
.content {
    
    color: #333;
    margin: 0 auto;
    text-align: center;
    padding-top: 20px;
}
</style>