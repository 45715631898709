<template>
  <div>
    <div class="pic" :class="isMobile ? 'mobile-pic' : ''">
      <div :class="isMobile ? 'mobile-group' : 'group'">
        <div class="group-title">关于我们</div>
        <div class="group-content">ABOUT US</div>
      </div>
    </div>
    <div class="body-box">
      <div class="break" :style="isMobile ? 'width:100%' : ''">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动管理</el-breadcrumb-item>
        <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item>

      </el-breadcrumb> -->
        <breadCrumb></breadCrumb>
      </div>
      <div class="nav">
        <div class="nav-box" :style="isMobile ? 'width:100%' : ''">
          <a href="#to-introduction" class="nav-box-item">黑盾简介</a>
          <a href="#to-culuture" class="nav-box-item">黑盾文化</a>
          <a href="#to-honor" class="nav-box-item">荣誉资质</a>
          <a href="#to-style" class="nav-box-item">黑盾风采</a>
          <a href="#to-message" class="nav-box-item">在线留言</a>
          <!-- <div class="nav-box-item" id='#to-culuture'>企业文化</div>
        <div class="nav-box-item" id='#to-honor' >荣誉资质</div>
        <div class="nav-box-item" id='#to-style'>企业风采</div>
        <div class="nav-box-item" id="#to-message">在线留言</div> -->
        </div>
        <!-- <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">网思简介</el-menu-item>
        <el-menu-item index="2">企业文化</el-menu-item>
        <el-menu-item index="3">荣誉资质</el-menu-item>
        <el-menu-item index="4">企业风采</el-menu-item>
        <el-menu-item index="5">在线留言</el-menu-item>
      </el-menu> -->
      </div>

      <div class="application-box">
        <div class="application">
          <div v-if="!isMobile" class="title-box2" style="position: relative">
            <div id="to-introduction" class="title2">黑盾简介</div>
            <div class="title-active"></div>
            <div class="title-eng2">COMPANY PROFILE</div>
          </div>
          <div v-else>
            <div class="com-title" :style="isMobile ? 'padding:0 0 50px' : ''">
              <h3 class="title-ch">
                黑盾简介
                <p class="title-en">COMPANY PROFILE</p>
              </h3>
            </div>
          </div>
          <div
            class="application2"
            :class="isMobile ? 'mobile-application2' : ''"
          >
            <div :class="isMobile ? 'mobile-contnet' : 'application-content'">
              <div class="application-introduction">
                <h2>{{ profileList.imgTitle }}</h2>
                <div class="introduction-active"></div>
                <p
                  v-html="profileList.imgDescribe"
                  class="introduction-content"
                ></p>
              </div>
              <img
                class="application-img"
                :class="isMobile ? 'mobile-application-img' : ''"
                :src="profileList.imgUrl"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="culuture">
        <div v-if="!isMobile">
          <div class="title-box" style="position: relative">
            <div id="to-culuture" class="title">黑盾文化</div>
            <div class="title-active"></div>
            <div class="title-eng" style="left: 765px">COMPANY CULUTURE</div>
          </div>
        </div>
        <div v-else>
          <div id="to-culuture" class="com-title">
            <h3 class="title-ch">
              黑盾文化
              <p class="title-en">COMPANY CULUTURE</p>
            </h3>
          </div>
        </div>
        <div v-if="!isMobile" class="culuture-content" style="">
          <div style="margin-left: 100px">
            <el-row :gutter="20">
              <el-col
                :span="7"
                v-for="(item, index) in culutureList"
                :key="index"
                class="bgpic1"
                :style="{ backgroundImage: 'url(' + item.imgUrl + ')' }"
              >
                <div class="culuture-group" style="">
                  <h2>{{ item.imgTitle }}</h2>
                  <div>{{ item.imgDescribe }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else class="mobile-culuture-content">
          <div
            class="mobile-culuture-item"
            v-for="(item, index) in culutureList"
            :key="index"
          >
            <img class="item-img" :src="item.imgUrl" alt="" />
            <div class="item-txt">
              <div class="txt-ch">{{ item.imgTitle }}</div>
              <div class="txt-en">{{ item.imgDescribe }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 80%; margin: 50px auto">
        <div>
          <div v-if="!isMobile" class="title-box" style="position: relative">
            <div id="to-honor" class="title">荣誉资质</div>
            <div class="title-active"></div>
            <div class="title-eng" style="left: 500px">
              HONOR AND QUALIFICATION
            </div>
          </div>
          <div v-else>
            <div id="to-honor" class="com-title">
              <h3 class="title-ch">
                荣誉资质
                <p class="title-en">HONOR AND QUALIFICATION</p>
              </h3>
            </div>
          </div>
          <div class="product">
            <div class="product-title" :style="isMobile ? 'width:100%' : ''">
              <div style="flex: 1; color: #333; cursor: pointer">
                <div
                  @click="getImg('honor')"
                  :class="{ 'active-button': activeButton === 'getImgHonor' }"
                >
                  产品证书
                </div>
              </div>
              <div style="flex: 1; color: #333; cursor: pointer">
                <div
                  @click="getImg('vip')"
                  :class="{ 'active-button': activeButton === 'getImgVip' }"
                >
                  荣誉证书
                </div>
              </div>

              <!-- <div style="flex: 1; color: #333">产品证书</div> -->
            </div>
            <div class="product-block" :style="isMobile ? 'width:100%' : ''">
              <template>
                <el-carousel>
                  <el-carousel-item
                    v-for="(item, index) in imgList"
                    :key="index"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                      "
                    >
                      <img
                        :src="item.imgUrl"
                        :alt="item.altText"
                        style="width: 600px; height: 300px; object-fit: cover"
                      />
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="style-box" :class="isMobile ? 'mobile-style-box' : ''">
        <div :style="isMobile ? 'padding:0 10px' : ''">
          <div v-if="!isMobile" class="title-box" style="position: relative">
            <div id="to-style" class="title">黑盾风采</div>
            <div class="title-active"></div>
            <div class="title-eng">CORPORATE STYLE</div>
          </div>
          <div v-else>
            <div id="to-style" class="com-title">
              <h3 class="title-ch" style="color: #fff">
                黑盾风采
                <p class="title-en">CORPORATE STYLE</p>
              </h3>
            </div>
          </div>
          <div class="product">
            <div class="product-block" :style="isMobile ? 'width:100%' : ''">
              <!-- <el-carousel
                trigger="click"
                height="388px"
                :autoplay="false"
                indicator-position="none"
                arrow="always"
                :loop="false"
              >
                <el-carousel-item v-for="(item, idx) in arr" :key="idx">
                  <div class="product-card-box">
                    <cardItem
                      v-for="(mItem, mIdx) in item"
                      :key="mIdx"
                      :itemMes="mItem"
                      @click.native="goDetail"
                    />
                  </div>
                </el-carousel-item>
              </el-carousel> -->
              <template>
                <el-carousel :interval="4000" type="card" height="300px">
                  <el-carousel-item
                    v-for="(item, index) in styleList"
                    :key="index"
                  >
                    <img
                      :src="item.imgUrl"
                      alt=""
                      :class="
                        isMobile ? 'mobile-carousel-image' : 'carousel-image'
                      "
                    />
                  </el-carousel-item>
                </el-carousel>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="message" style="">
        <div>
          <div v-if="!isMobile" class="title-box" style="position: relative">
            <div id="to-message" class="title">在线留言</div>
            <div class="title-active"></div>
            <div class="title-eng">ONLINE MESSAGE</div>
          </div>
          <div v-else>
            <div id="to-message" class="com-title">
              <h3 class="title-ch">
                在线留言
                <p class="title-en">ONLINE MESSAGE</p>
              </h3>
            </div>
          </div>
        </div>
        <div class="message-content" style="margin-top: 50px">
          <el-form :model="form" ref="formRef" :rules="rules">
            <el-form-item prop="msgUsername">
              <div style="display: flex">
                <el-input
                  v-model="form.msgUsername"
                  placeholder="您的姓名"
                  class="input-name"
                  style=""
                ></el-input>
                <el-input
                  v-model="form.msgUsertel"
                  placeholder="您的电话"
                  class="input-phone"
                  style=""
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="msgContent">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="form.msgContent"
                class="input-textarea"
                style=""
              >
              </el-input>
            </el-form-item>
            <div style="" class="message-btn">
              <el-button type="primary" @click="submitForm">立即提交</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addMsg } from "@/api/msg";
import { listImg } from "@/api/img";
import breadCrumb from "@/components/breadCrumb.vue";
export default {
  compoments: {
    breadCrumb,
  },
  data() {
    return {
      activeButton: null,

      form: {
        msgUsername: "",
        msgUsertel: "",
        msgContent: "",
      },
      //       rules: {
      //         msgUsername: [
      //         { required: true, message: '请输入名称', trigger: 'blur' },
      //             { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
      //         ],
      //         msgUsertel: [
      //   { required: true, message: '请输入电话', trigger: 'blur' },
      //   {
      //     validator: (rule, value, callback) => {
      //       // 使用正则表达式来校验11位电话号码
      //       const phoneRegex = /^\d{11}$/; // 匹配11位连续数字
      //       if (!phoneRegex.test(value)) {
      //         callback(new Error('请输入有效的11位电话号码'));
      //       } else {
      //         callback();
      //       }
      //     },
      //     trigger: 'blur'
      //   }
      // ],
      // msgContent: [
      //   { required: true, message: '请输入内容', trigger: 'blur' },
      //   {
      //     validator: (rule, value, callback) => {
      //       // 使用正则表达式来确保至少有一个汉字
      //       const hasChineseChar = /[\u4e00-\u9fa5]/.test(value);
      //       if (!value || !hasChineseChar) {
      //         callback(new Error('内容至少包含一个汉字'));
      //       } else {
      //         callback();
      //       }
      //     },
      //     trigger: 'blur'
      //   }
      // ],
      //       },
      loading: false,
      imgList: [],
      styleList: [],
      queryParams: {
        imgPosition: "",
        pageSize: 999,
      },
      honorList: [],
      profileList: {},
      culutureList: [],
      rules: {},
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  created() {
    this.imgList = [];
    this.getList();
    setTimeout(() => {
      this.getImg("honor");
    });
  },

  methods: {
    setActiveButton(buttonName) {
      this.activeButton = buttonName;
    },
    getImg(params) {
      if (params == null) {
        params = "honor";
      }
      this.queryParams.imgPosition = params;
      this.setActiveButton(
        "getImg" + params.charAt(0).toUpperCase() + params.slice(1)
      ); // 转换为驼峰命名

      this.getList();
    },
    submitForm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          //console.log(this.form);
          if (
            this.form.msgUsername == "" ||
            this.form.msgUsertel == "" ||
            this.form.msgContent == ""
          ) {
            this.$message.error("请填写完整信息");
            return false;
          } else if (this.form.msgUsertel.length != 11) {
            this.$message.error("请填写正确的电话号码");
            return false;
          }
          this.addMsglist(this.form); // 提交表单数据
          //console.log("表单验证通过", this.form);
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.form = { name: "", phone: "", content: "" }; // 清空表单数据
        } else {
          //console.log("表单验证失败！");
          return false;
        }
      });
    },
    addMsglist(data) {
      addMsg(data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("添加留言失败", err);
        });
    },
    /** 查询照片列表 */
    getList() {
      this.loading = true;
      listImg(this.queryParams).then((response) => {
        //console.log(response.rows,"111111111111111111");
        this.loading = false;
        this.imgList = response.rows;
        this.imgList.forEach((item) => {
          item.imgUrl = this.$baseImgUrl + item.imgUrl;
          if (item.imgPosition == "style") {
            this.styleList.push(item);
          }
          if (item.imgPosition == "culuture") {
            this.culutureList.push(item);
          }
          if (item.imgPosition == "profile") {
            this.profileList = item;
            // console.log(this.profileList,"2222222222222222");
          }
        });

        //this.imgList.imgUrl=this.$baseImgUrl+this.imgList.imgUrl;
        // this.imgList.forEach(item=>{
        //   if(item.imgPosition == "honor"){
        //     item.imgUrl=this.$baseImgUrl+item.imgUrl
        //     this.honorList.push(item)
        //   }
        // })
        // console.log("honorList",this.honorList);
        // console.log("imgList", this.imgList);
        // console.log(this.culutureList,'culuture');

        this.total = response.total;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.style-box {
  width: 80%;
  margin: 0 auto;
  height: 600px;
}
.mobile-style-box {
  background: #161e2e;
  width: 100% !important;
}
.mobile-carousel-image {
  width: 100%;
  height: 100%;
}
.com-title {
  padding: 3.55rem 0 3.68rem;
  text-align: center;
  .title-ch {
    position: relative;
    font-size: 1.64rem;
    color: #000;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: -0.64rem;
      transform: translateX(-50%);
      width: 2.73rem;
      height: 0.09rem;
      background-color: #0096a3;
    }
  }
  .title-en {
    position: absolute;
    left: 50%;
    top: 0.91rem;
    transform: translateX(-50%);
    color: rgba(153, 153, 153, 0.15);
    z-index: 1;
    font-size: 1.2rem;
    white-space: nowrap;
    text-indent: 0;
  }
}
.mobile-pic {
  height: 400px !important;
  .mobile-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .group-title {
      font-size: 50px;
    }
  }
}
.mobile-application2 {
  background: url(@/assets/about3x.png) no-repeat right bottom !important;
  background-size: cover !important;
  .mobile-contnet {
    padding: 2.09rem 1.73rem !important;

    .mobile-application-img {
      width: 100%;
      height: 200px;
    }
  }
}
.mobile-culuture-content {
  padding: 0 10px;
  .mobile-culuture-item {
    position: relative;
    height: 11.82rem;
    width: 100% !important;
    margin-bottom: 0.5rem;
    .item-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    .item-txt {
      position: absolute;
      bottom: 1.36rem;
      left: 0.91rem;
      z-index: 3;
      transition: all 0.3s;
      text-align: start;
      .txt-ch {
        font-size: 1rem;
        margin-bottom: 0.45rem;
        font-weight: normal;
        color: #fff;
      }
      .txt-en {
        line-height: 1.09rem;
        color: #fff;
      }
    }
  }
}
</style>

<style scoped lang="less">
.pic {
  width: 100%;
  height: 600px;
  background: url(@/assets/product.png) no-repeat center;
  background-size: cover;
  .group {
    margin: 0 auto;
    padding-top: 200px;
    text-align: center;
    width: 100%;
    .group-title {
      font-size: 50px;
    }
    .group-content {
      margin-top: 20px;
    }
  }
}
.break {
  width: 60%;
  text-align: left;
  margin: 0 auto;
  padding-top: 20px;
}
.title {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  color: #333;
}
.title::before {
  content: "";
  //background-image: url("@/assets/before.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-right: 20px;
}
.title::after {
  content: "";
  // background-image: url("@/assets/after.png");
  background-size: cover;
  width: 142px;
  height: 16px;
  margin-left: 20px;
}
.title-eng {
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: rgba(153, 153, 153, 0.15);
  font-size: 36px;
  top: 0;
  left: 600px;
}
.nav {
  width: 100%;
  height: 75px;
  line-height: 75px;
  margin: 0 auto;
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding-top: 50px;
  color: #333;
  .nav-box {
    width: 45%;
    display: flex;
    margin: 0 auto;
    text-align: center;
    .nav-box-item {
      flex: 1;
    }
  }
}
.title-active {
  border-bottom: 2px solid skyblue;
  width: 90px;
  height: 10px;
  text-align: center;
  margin: 0 auto;
}
.application-box {
  width: 100%;
  .application {
    width: 100%;
    background-color: #fff;
    margin: 50px auto;

    .title-box2 {
      margin: 30px 0;

      .title2 {
        position: relative;
        z-index: 1;
        padding-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #000;
      }
      .title2::before {
        content: "";
        //background-image: url("@/assets/before.png");
        background-size: cover;
        width: 142px;
        height: 16px;
        margin-right: 20px;
      }
      .title2::after {
        content: "";
        //background-image: url("@/assets/after.png");
        background-size: cover;
        width: 142px;
        height: 16px;
        margin-left: 20px;
      }
      .title-eng2 {
        position: absolute;
        z-index: 0;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        color: rgba(153, 153, 153, 0.15);
        font-size: 36px;
        top: 30px;
        left: 780px;
      }
    }
    .application2 {
      width: 100%;
      background: url("@/assets/about3x.png");
      background-size: cover;
      background-position: center;
      height: 700px;
      .application-content {
        width: 75%;
        margin: 0 auto;
        padding-top: 100px;
        display: flex;

        .application-img {
          width: 660px;
          height: 340px;
          flex: 1;
          padding-top: 100px;
        }
        .application-introduction {
          flex: 1;
          padding-top: 80px;
          margin-right: 60px;
          .introduction-active {
            border-bottom: 1px solid skyblue;
            width: 90px;
            height: 30px;
          }
          .introduction-content {
            padding-top: 30px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.cases-img:hover {
  transform: scale(1.02);
}
.bgpic1 {
  //background-image: url(@/assets/about1.png);
  background-size: cover;
  background-position: center;
  height: 260px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}
.bgpic1:hover .culuture-group {
  top: 100px;
  text-align: center;
  right: 30px;
}
.bgpic2 {
  background-image: url(@/assets/about2.png);
  background-size: cover;
  background-position: center;
  height: 260px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}
.bgpic3 {
  background-image: url(@/assets/about3.png);
  background-size: cover;
  background-position: center;
  height: 260px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}
.bgpic4 {
  background-image: url(@/assets/about4.png);
  background-size: cover;
  background-position: center;
  height: 260px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}
.bgpic5 {
  background-image: url(@/assets/about5.png);
  background-size: cover;
  background-position: center;
  height: 260px;
  margin-left: 20px;
  margin-top: 20px;
  position: relative;
}
.culuture {
  margin: 0 auto;
  text-align: center;
  .culuture-content {
    width: 80%;

    margin: 50px auto;
    text-align: left;
    .culuture-group {
      position: absolute;
      bottom: 30px;
      left: 20px;
    }
  }
}

.message {
  width: 80%;
  margin: 0 auto;
  text-align: left;
  .message-content {
    margin: 0 auto;
    text-align: center;
    position: relative;
    // .input-name {
    //   width: 40%;
    //   display: inline-block;
    //   position: absolute;
    //   left: 135px;
    //   top: 0;
    // }
    // .input-phone {
    //   width: 40%;
    //   display: inline-block;
    //   position: absolute;
    //   right: 140px;
    //   top: -22px;

    //   //margin-left: 30px;
    // }
    .input-textarea {
      width: 82%;
      margin-top: 30px;
    }
    .message-btn {
      margin-top: 30px;
      padding-bottom: 50px;
    }
  }
}
.body-box {
  width: 100%;
  background-color: #fff;
}
a {
  text-decoration: none;
  color: #333;
}
.product {
  margin-top: 47px;
  .product-title {
    display: flex;
    margin: 0 auto;
    text-align: center;
    width: 20%;
    padding-bottom: 30px;
  }
  .product-block {
    width: 1200px;
    height: 389px;
    margin: 0 auto;
    text-align: center;
    .product-card-box {
      display: flex;
      cursor: pointer;
    }
  }
}
.carousel-image {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
p {
  line-height: 2.5; /* 调整行间距 */
  margin-bottom: 10px; /* 可选：段落间距 */
  text-indent: 2em;
}
.active-button {
  color: #0096a3; /* 你可以自定义高亮颜色 */
  //color: #fff; /* 你可以自定义高亮文本颜色 */
  /* 其他你想要的高亮样式 */
}
</style>
