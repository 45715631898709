import request from '@/utils/request'

// 查询加盟列表
export function listJoin(query) {
  return request({
    url: '/biz/join/list',
    method: 'get',
    params: query
  })
}

// 查询加盟详细
export function getJoin(id) {
  return request({
    url: '/biz/join/' + id,
    method: 'get'
  })
}

// 新增加盟
export function addJoin(data) {
  return request({
    url: '/biz/join',
    method: 'post',
    data: data
  })
}

// 修改加盟
export function updateJoin(data) {
  return request({
    url: '/biz/join',
    method: 'put',
    data: data
  })
}

// 删除加盟
export function delJoin(id) {
  return request({
    url: '/biz/join/' + id,
    method: 'delete'
  })
}
